<template>
	<section
		class="projects"
		id="projects"
		v-bind:class="{ 'projects--single': this.single }"
	>
		<div class="container" v-if="!this.single">
			<header class="projects__title">
				<h2 class="projects__title__sup">Selected projects</h2>
				<p class="projects__title__main">
					Few interesting projects I helped create
				</p>
			</header>
		</div>

		<article
			v-for="project in this.list"
			:key="project.id"
			:style="{
				'--project-color-h': this.hexToHSL(project.color).h,
				'--project-color-s': this.hexToHSL(project.color).s + '%',
				'--project-color-l': this.hexToHSL(project.color).l + '%',
				'--project-text': !project.schema ? '#fff' : '',
			}"
			class="projects__area"
			v-bind:class="{ odd: this.list.indexOf(project) % 2 == 0 }"
			:ref="project.id"
		>
			<div class="row row-nogutter">
				<div class="col-xs-12 col-md-6">
					<div class="projects__area__images">
						<div class="projects__area__images__monitor mockup-monitor">
							<div>
								<picture>
									<source
										:media="`(min-width: ${$root.bpMd}px)`"
										:srcset="require('@/assets/projects/' + project.gallery[0].desktop + '.webp')"
										width="960"
										height="540"
									/>
								<img
									:srcset="require('@/assets/projects/' + project.gallery[0].desktop + '-m@1.webp') + ' 1x, ' + require('@/assets/projects/' + project.gallery[0].desktop + '-m@2.webp') + ' 2x'"
									:alt="'A screenshot of ' + project.name + ' in a desktop viewport'"
									width="400"
									height="225"
									loading="lazy"
								/>
								</picture>
							</div>
						</div>

						<div
							class="projects__area__images__phone mockup-phone"
							:style="'transform: translateY(' + this.parallax(project) + 'px)'"
						>
							<div>
								<picture>
									<source
										:media="`(min-width: ${$root.bpMd}px)`"
										:srcset="require('@/assets/projects/' + project.gallery[0].mobile + '.webp')"
										width="300"
										height="540"
									/>
									<img
										:srcset="require('@/assets/projects/' + project.gallery[0].mobile + '-m@1.webp') + ' 1x, ' + require('@/assets/projects/' + project.gallery[0].mobile + '-m@2.webp') + ' 2x'"
										:alt="'A screenshot of ' + project.name + ' in a mobile viewport'"
										width="135"
										height="243"
										loading="lazy"
									/>
								</picture>
							</div>
						</div>
					</div>
				</div>
				<div class="col-xs-12 col-md-6">
					<div class="projects__area__half-container">
						<div class="projects__area__content">
							<h3 v-if="!this.single" class="projects__area__title">{{ project.name }}</h3>
							<h1 v-else class="projects__area__title">{{ project.name }}</h1>
							<div v-html="project.abstract"></div>
							<router-link
								class="btn"
								:to="{ name: 'project', params: { id: project.id } }"
								v-if="!this.single"
							>
								More info<span class="visuallyhidden"> about {{ project.name }}</span>
							</router-link>
						</div>
					</div>
				</div>
			</div>
			<div v-if="this.$root.theme == 'dark' && this.single" class="projects__area__bg">
				<div class="northern-lights">
					<div v-for="i in this.$root.isMd ? 20 : 4" :key="i"></div>
				</div>
			</div>
		</article>
	</section>
</template>

<script>
import waypoint from "@/mixins/waypoint.js";
import colorSpace from "@/mixins/colorSpace.js";

export default {
	name: "ProjectsList",
	components: {},
	mixins: [waypoint, colorSpace],
	data() {
		return {
		};
	},
	props: {
		list: undefined,
		single: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
	},
	methods: {
		parallax: function (project) {
			const self = this;
			const factor = self.$root.isMd ? -10 : -22;
			const scroll = self.$root.scroll;

			if (!self.$refs[project.id]) return 0;
			const offsetTop = self.$refs[project.id].offsetTop;

			if (scroll + window.innerHeight + 100 > offsetTop) {
				return (offsetTop - scroll - window.innerHeight / 4) / factor;
			} else {
				return 0;
			}
		},
	},
	mounted() {
		const self = this;

		self.list.forEach((project) => {
			//add schema to data
			project.schema = self.hexContrast(project.color);

			//add waypoint to each project element
			const img = self.$refs[project.id].querySelector(".projects__area__images");
			this.waypoint(img,0.25,() => {img.classList.add("enter");},
				() => {
					//img.classList.remove("enter")
				}
			);
		});
	},
};
</script>

<style lang="scss">
@import "@/styles/variables";
@import "@/styles/mixins";

$project-color: hsl(
	var(--project-color-h),
	var(--project-color-s),
	var(--project-color-l)
);

$project-color-lighten: hsl(
	var(--project-color-h),
	var(--project-color-s),
	calc(var(--project-color-l) + var(--project-color-l) * 0.15)
);

$monitor-bg: #d1d1d1;
$monitor-dots: $project-color;
$phone-bg: #d1d1d1;

.projects {
	display: block;
	padding-top: 8rem;

	@media (min-width: $bp-md) {
		padding-top: 12rem;
	}

	&.projects--single {
		padding-top: 0;
	}
}

.projects__title {
	margin-bottom: 4rem;

	@media (min-width: $bp-md) {
		margin-bottom: 4rem;
	}

	&__sup {
		@include text-size($font-size-small);
		font-weight: 900;
		text-transform: uppercase;
		color: $primary;
		margin-bottom: 1rem;

		@include color-scheme(dark) {
			color: $primary;
		}

		@media (min-width: $bp-md) {
			margin-bottom: 2rem;
		}
	}
	&__main {
		@include text-size($font-size-largest);
		font-weight: 700;

		@media (min-width: $bp-md) {
			@include text-size($font-size-largest);
		}
	}
}

.projects__area {
	padding: 0 0 8rem;
	color: $grey4;

	&:nth-child(even) {
		background: $grey1;
	}

	@media (min-width: $bp-md) {
		padding: 16rem 0 12rem;
	}

	@media (min-width: $bp-lg) {
		padding: 16rem 0 4rem;
	}

	.row {
		align-items: center;
	}

	&.odd .row {
		flex-direction: row-reverse;
	}

	.projects--single & {
		position: relative;
		z-index: 1;
		background: $bg-gradient;
		padding-top: $header-heigh-mobile-affixed + 4;
		color: $grey0;

		@include color-scheme(dark) {
			color: $white;
		}

		@media (min-width: $bp-md) {
			padding-top: $header-heigh-desktop-affixed + 16;
		}
	}

	&__half-container {
		padding: 0 4rem;
		margin: auto;

		@media (min-width: $bp-sm) {
			max-width: $container-sm;
		}

		@media (min-width: $bp-md) {
			margin-left: 0;
			margin-right: auto;
			max-width: calc(#{$container-md}/ 2);

			.odd & {
				margin-right: 0;
				margin-left: auto;
			}
		}

		@media (min-width: $bp-lg) {
			max-width: calc(#{$container-lg}/ 2);
			padding: 0;
		}
	}

	&__content {
		margin-top: 0rem;
		margin-bottom: 4rem;

		@media (min-width: $bp-md) {
			max-width: 50rem;
			margin-right: 0;
			margin-left: auto;
			margin-top: 0;
			text-align: right;
			//min-height: 56rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-end;
		}

		.odd & {
			@media (min-width: $bp-md) {
				text-align: left;
				margin-right: auto;
				margin-left: 0;
				align-items: flex-start;
			}
		}

		p {
			margin-bottom: 1rem;
		}

		.projects--single & {
			margin-bottom: 0;
		}
	}

	&__title {
		@include text-size($font-size-larger);
		font-weight: 700;
		margin-bottom: 2rem;
	}
}

.projects__area__images {
	margin-bottom: 4rem;
	position: relative;
	margin-top: -1rem;
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-left: 1rem;
	padding-right: 1rem;
	width: $container-sm;
	display: table;

	@media (min-width: $bp-md) {
		padding: 0;
		margin: 0;
		max-height: 60rem;
		width: 100%;
		max-width: 120rem;
		//height: 30rem;
	}

	@media (min-width: $bp-lg) {
		margin-bottom: -6rem;
		margin-right: 0;
		margin-left: auto;

		.odd & {
			margin-left: 0;
			margin-right: auto;
		}
	}

	.projects__area__images__monitor {
		background-color: $project-color;
		--monitor-dots: #{$project-color};

		> div {
			&:before {
				content: "";
				left: 0;
				top: 0;
				width: 110%;
				height: 100%;
				background-color: $project-color-lighten;
				position: absolute;
				transform: translateX(100%);
				transition: transform 1s ease;
				transition-delay: 0.3s;
				z-index: 2;

				@media (prefers-reduced-motion) {
					transition-duration: 0s;
					transition-delay: 0s;
				}

				@at-root .enter#{&} {
					transform: translateX(-100%);
				}
			}
		}

		img {
			transition: opacity 0.01s linear;
			transition-delay: 0.6s;
			opacity: 0;

			@media (prefers-reduced-motion) {
				transition-duration: 0s;
				transition-delay: 0s;
			}

			@at-root .enter#{&} {
				opacity: 1;
			}
		}

	}

	.projects__area__images__phone {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 5;
		max-width: none;
		height: auto;
		width: 33.33%;
		left: 5%;
		right: auto;
		top: 0;
		opacity: 0;
		transition: opacity 0.5s ease;
		transition-delay: 1.1s;

		@media (prefers-reduced-motion) {
			transition-duration: 0s;
			transition-delay: 0s;
		}
		
		@at-root .enter#{&} {
			opacity: 1;
		}
		
		.odd & {
			right: 3rem;
			left: auto;
		}
		
	@media (max-width: $bp-sm-max) {
		right: 3rem;
		left: auto;
	}
	}
}

.projects--single {
	.projects__area__bg {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		z-index: -1;
		overflow: hidden;

		&:after {
			content: "";
			position: absolute;
			bottom: -16rem;
			right: -16rem;
			left: -16rem;
			height: 16rem;
			background: $primary;
			box-shadow: 0 0 16rem var(--primary2);
		}

		.northern-lights {
			opacity: 0.6;
			box-shadow: inset 0 0 1rem $primary;
		}
	}
}
</style>
