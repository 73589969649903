<template>
	<section class="skills" id="skills">
		<header class="skills__title">
			<h2>
				<span>Tech</span>
				<span class="skills__title__divider">&</span>
				<span>skillset</span>
			</h2>
		</header>
		<div class="row skills__row" aria-roledescription="carousel">
			<div class="col-xs-12 col-md-6">
				<div class="skills__tags" role="tablist" aria-label="Skill slides" ref="carouselNav">
						<div v-for="skill in list" :key="skill.name">
							<button
								type="button"
								@click="changeSlide(list.indexOf(skill))"
								:class="{ active: list.indexOf(skill) == currentSlide }"
								:aria-selected="list.indexOf(skill) == currentSlide ? true : false" :aria-controls="skill.id" role="tab"
							>
								{{ skill.name }}
							</button>
						</div>
				</div>
				<div class="skills__image" ref="bg" aria-hidden="true">
					<picture
						v-for="skill in list"
						:key="skill.name"
						:ref="'bg' + list.indexOf(skill)"
					>
						<source
							:media="`(min-width: 1920px)`"
							:srcset="require('@/assets/tech/' + skill.image + '-xl.webp')"
						/>
						<source
							:media="`(min-width: ${$root.bpLg}px)`"
							:srcset="require('@/assets/tech/' + skill.image + '-lg.webp')"
						/>
						<source
							:media="`(min-width: ${$root.bpMd}px)`"
							:srcset="require('@/assets/tech/' + skill.image + '-md.webp')"
						/>
						<source
							:media="`(min-width: ${$root.bpSm}px)`"
							:srcset="require('@/assets/tech/' + skill.image + '-sm.webp')"
						/>
						<img
							:src="require('@/assets/tech/' + skill.image + '-xs.webp')"
							alt=""
							loading="lazy"
						/>
					</picture>
				</div>
			</div>
			<div class="col-xs-12 col-md-6">
				<div class="skills__content" aria-live="polite" ref="carousel">
					<swiper
						v-if="!$root.isMd"
						@swiper="onSwiper"
						@slideChangeTransitionEnd="onSlideChange"
						:slides-per-view="1.3"
						:loop="true"
						:centeredSlides="true"
						class="skills__carousel"
					>
						<swiper-slide
							class="skills__item"
							v-for="skill in list"
							:key="skill.id"
							role="tabpanel"
							aria-roledescription="slide"
							:aria-label="list.indexOf(skill) + 1 + ' / ' + list.length"
						>
							<div :ref="'slide' + list.indexOf(skill)">
								<h3 class="skills__content__title">{{ skill.name }}</h3>
								<div v-html="skill.text"></div>
							</div>
						</swiper-slide>
					</swiper>

					<div v-if="$root.isMd" ref="text" class="skills__container">
						<div
							class="skills__item"
							v-for="skill in list"
							:key="skill.name"
							:ref="'text' + list.indexOf(skill)"
							:id="skill.id"
							role="tabpanel"
							aria-roledescription="slide"
							:aria-label="list.indexOf(skill) + 1 + ' / ' + list.length"
						>
							<div>
								<h3 class="skills__content__title">{{ skill.name }}</h3>
								<div v-html="skill.text"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import keyboard from "@/mixins/keyboard.js";
import SwiperCore, { /* A11y */ } from "swiper";
//SwiperCore.use([A11y]);
import "swiper/swiper.scss";
//import "swiper/components/a11y/a11y.scss";

export default {
	name: "Skills",
	components: {
		Swiper,
		SwiperSlide,
	},
	mixins: [keyboard],
	data() {
		return {
			currentSlide: 0,
			idList: []
		};
	},
	props: {
		list: undefined,
	},
	mounted() {
		if (this.$refs["text0"]) {
			//manage heights for desktop slides
			let heights = [];
			this.list.forEach((e, n) => {
				heights.push(this.$refs["text" + n].offsetHeight);
			});
			const maxHeight = Math.max(...heights);
			this.list.forEach((e, n) => {
				this.$refs["text" + n].style.height = maxHeight + "px";
			});
			this.$refs["text"].style.height = maxHeight + "px";
		}
		else {
			//add IDs to mobile slides (can't include them in template or they'll be duplicated by swiper)
			const slides = this.$refs.carousel.querySelectorAll('.swiper-slide');
			for (let slide of slides) {
				if (!slide.classList.contains('swiper-slide-duplicate')) {
					const i = slide.dataset.swiperSlideIndex;
					slide.setAttribute('id', this.list[i].id);
				}
			}
		}

		this.tablist(this.$refs.carouselNav);
		this.changeSlide(0);


	},
	methods: {
		onSwiper(swiper) {
			this.swiper = swiper;
		},
		onSlideChange(swiper) {
			this.currentSlide = swiper.realIndex;
			this.manageContent("bg").then(this.manageKeyboard);
		},
		changeSlide(i) {
			this.currentSlide = i;
			this.swipeCards();
		},
		swipeCards() {
			const self = this;
			self.manageContent("bg").then(self.manageKeyboard);

			if (self.swiper) {
				//const delta = this.swiper.activeIndex - this.swiper.realIndex;
				this.swiper.slideTo(self.currentSlide + 2);
			} else if (self.$refs["text0"]) {
				self.manageContent("text").then(self.manageKeyboard);
			}
		},
		manageContent(ref) {
			return new Promise((resolve, reject) => {
				const self = this;
				const allEl = self.$refs[ref].children;
				const currentEl = Array.from(allEl).filter((el) => {
					return el.classList.contains("in");
				})[0];
				if (currentEl == self.$refs[ref + self.currentSlide]) return false;
				const oldEl = Array.from(allEl).filter(
					(el) => !el.classList.contains("in") && !el.classList.contains("out")
				)[0];
				const newEl = self.$refs[ref + self.currentSlide];

				self.loadAsset(newEl).then(function () {
					if (oldEl) oldEl.classList.add("out");

					setTimeout(() => {
						for (let el of allEl) {
							el.classList.add("out");
						}
						if (currentEl) currentEl.classList.remove("in", "out");
						newEl.classList.remove("out");
						newEl.classList.add("in");
						resolve();
					}, 0);
				});
			})

		},
		loadAsset(asset) {
			return new Promise((resolve, reject) => {
				if (asset.tagName === "PICTURE") asset = asset.querySelector("img");
				if (asset.tagName !== "IMG") {
					resolve();
					return;
				}

				if (asset.complete) {
					resolve();
				} else {
					asset.addEventListener("load", resolve);
					asset.addEventListener("error", resolve);
					asset.setAttribute("loading", "auto");
				}
			});
		},
		manageKeyboard() {
			this.toggleTab(this.$refs.carousel.querySelectorAll('.skills__item'), false)
			this.toggleTab(this.$refs.carousel.querySelectorAll('.skills__item.in,.skills__item.swiper-slide-active'), true)
		}
	},
};
</script>

<style lang="scss">
@import "@/styles/variables";
@import "@/styles/mixins";

$md-padding-top: 20rem;
$lg-padding-top: 32rem;
$skills-bg: #{$grey1};
$skills-img-bg: #23272f;

.skills {
	padding: 16rem 0 8rem;
	background-color: $skills-bg;
	position: relative;
	z-index: 1;
	overflow: hidden;

	@media (min-width: $bp-md) {
		padding: $md-padding-top 0;
	}

	@media (min-width: $bp-lg) {
		padding: $lg-padding-top 0;
	}
}

.skills__container {
	position: relative;
}

.skills__carousel {
	margin: 0 #{-$container-padding};

	@media (min-width: $bp-md) {
		margin: 0;
	}
}

.skills__item {
	width: 100%;
	padding: 0 2rem;

	@media (min-width: $bp-md) {
		display: flex;
		width: 100%;
		flex-direction: column;
		justify-content: center;
		position: absolute;
		transition: transform 0.5s ease, opacity 0.5s ease-out;
		transform: translate3d(-10%, 100%, 0);
		opacity: 0;
		padding: 0;

		@media (prefers-reduced-motion) {
			transition-duration: 0s, 0s;
		}

		&.in {
			transform: none;
			transition-timing-function: ease, ease-in;
			opacity: 1;
		}

		&.out {
			transform: translate3d(10%, -100%, 0);
			transition: none;
			opacity: 0;
		}
	}
}

.skills__row {
	margin: 0;
	align-items: center;

	> div {
		position: relative;

		@media (min-width: $bp-md) {
			position: static;
		}
	}
}

.skills__image {
	position: absolute;
	left: 0;
	right: 0;
	z-index: -2;
	top: -24rem;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: $skills-img-bg;

	@media (min-width: $bp-md) {
		top: 0;
	}

	picture {
		height: 100%;
		width: 100%;
		left: 0;
		right: 0;
		position: absolute;
		transition: opacity 0.5s ease;
		opacity: 0;

		@media (prefers-reduced-motion) {
			transition-duration: 0s!important;
		}

		&.in {
			opacity: 1;
		}

		&.out {
			opacity: 0;
		}

		@media (min-width: $bp-md) {
			transition: transform 0.5s ease;
			transform: translate3d(10%, -100%, 0);
			width: 125%;
			left: -12.5%;
			right: -12.5%;
			opacity: 1;

			&.in {
				transform: none;
			}

			&.out {
				transform: translate3d(-10%, 100%, 0);
				transition: none;
			}
		}

		img {
			width: 100%;
			height: 100%;
			display: block;
			object-fit: cover;
		}
	}

	@media (min-width: $bp-md) {
		width: calc(50% + 10rem);
		right: auto;
		min-height: 100%;
		bottom: 0;
	}
}

.skills__title {
	@include text-size($font-size-largest, strict);
	font-weight: 900;
	text-align: center;
	margin-bottom: 4rem;
	position: relative;

	@media (min-width: $bp-md) {
		margin-bottom: 8rem;
	}

	* {
		font-size: inherit;
	}

	&:before {
		//faux bg to trick lighthouse. actual contrast provided by text-shadow
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		background-color: $skills-img-bg;
		z-index: -1;
		opacity: 0.001;
	}

	span {
		display: inline-block;
		position: relative;
		z-index: 1;
		color: $white;
		text-shadow: 0 0 2rem $skills-img-bg, 0 0 4rem $skills-img-bg;

		&:not(:last-child) {
			margin-right: 0.3em;
		}

		@media (min-width: $bp-md) {
			width: 40%;
			text-align: right;

			&.skills__title__divider {
				background-color: $skills-bg;
				border-radius: 100%;
				text-align: center;
				width: 6rem;
				height: 6rem;
				line-height: 6rem;
				display: inline-block;
				vertical-align: middle;

				@include color-scheme(light) {
					color: $text-color;
				}

				@media (min-width: $bp-md) {
					text-shadow: none;
				}

				& ~ span {
					text-align: left;

					@include color-scheme(light) {
						color: $text-color;
					}

					@media (min-width: $bp-md) {
						text-shadow: none;
					}
				}
			}
		}
	}
}

.skills__tags {
	text-align: center;
	padding: 0 4rem 4rem;
	color: $grey4;

	@include color-scheme(dark) {
		color: $grey0;
	}

	@media (min-width: $bp-sm) {
		padding: 0;
		padding-bottom: 8rem;
		margin: auto;
		max-width: $container-sm;
		padding-left: $container-padding;
		padding-right: $container-padding;
	}

	@media (min-width: $bp-md) {
		text-align: right;
		max-width: 65rem;
		margin-right: 0;
		margin-left: auto;
		padding-left: 0;
		padding-right: 12rem;
		padding-bottom: 0;
	}

		div {
			display: inline-block;

			button,
			a {
				@include text-size($font-size-small, strict);
				background-color: $grey1;
				padding: 1rem 2rem;
				border-radius: 4rem;
				margin-right: 1rem;
				margin-bottom: 1rem;
				transition: background-color 0.2s ease;
				@include link-underline(never);

				@include color-scheme(dark) {
					background-color: $grey3;
				}

				&.active {
					background-color: $accent;

					@include color-scheme(dark) {
						background-color: $primary;
					}
				}
			}
	}
}

.skills__content {
	padding: 2rem 2rem 0;

	@media (min-width: $bp-sm) {
		padding: 2rem;
		padding-top: 0;
		margin: auto;
		max-width: $container-sm;
		padding-left: $container-padding;
		padding-right: $container-padding;
	}

	@media (min-width: $bp-md) {
		max-width: 64rem;
		padding-right: 0;
		padding-left: 4rem;
		margin: 0;
	}

	&:before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		background: $skills-bg;
		z-index: -1;
		transform: skewY(4deg);
		transform-origin: top right;

		@media (min-width: $bp-md) {
			width: 50%;
			transform: skewX(-12deg);
			transform-origin: center $md-padding-top;
			left: auto;
		}

		@media (min-width: $bp-lg) {
			transform-origin: center $lg-padding-top;
		}
	}

	p:not(:last-child) {
		margin-bottom: 1rem;
	}

	.skills__content__title {
		font-weight: 900;
		@include text-size($font-size-large);
		margin-bottom: 1rem;
	}
}
</style>
