<template>
	<footer class="footer">
		<div class="container">
			<div class="row">
				<div class="col-xs-12 col-md-4">
					<nav class="footer__nav" aria-label="footer navigation">
						<ul>
							<li>
								<router-link to="/#top"><font-awesome-icon icon="caret-right" />Home</router-link>
							</li>
							<li>
								<router-link to="/#about"><font-awesome-icon icon="caret-right" />About</router-link>
							</li>
							<li>
								<router-link to="/#skills"><font-awesome-icon icon="caret-right" />Tech</router-link>
							</li>
							<li>
								<router-link to="/#knowledge"><font-awesome-icon icon="caret-right" />Knowledge</router-link>
							</li>
							<li>
								<router-link to="/#projects"><font-awesome-icon icon="caret-right" />Projects</router-link>
							</li>
						</ul>
					</nav>
				</div>
				<div class="col-xs-12 col-md-4">
					<div class="footer__toggles">
						<div>
							<button
								@click="this.$root.toggleTheme()"
								v-bind:class="{ active: this.$root.theme == 'light' }"
							>
								<span class="switch"></span>
								Toggle dark mode
							</button>
						</div>
						<div>
							<button
								@click="this.$root.toggleGrid()"
								v-bind:class="{ active: this.$root.grid == 'on' }"
							>
								<span class="switch"></span>
								Toggle grid
							</button>
							<span class="footer__toggles__info">
								<button
									title="What's this?"
									@click="this.$root.$refs.modalGrid.openModal()"
								>
									<font-awesome-icon icon="question-circle" />
								</button>
							</span>
						</div>
					</div>
				</div>
				<div class="col-xs-12 col-md-4 col-md-first">
					<div class="footer__brand">
						<img src="@/assets/logo.svg" alt="" height="32" />
						<div>
							<span class="footer__brand__copyright">
								Created by
								Riccardo Farina
								© {{ new Date().getFullYear() }}
								<br />
								All rights reserved
							</span>
						</div>
					</div>
				</div>
			</div>
			<ul class=""></ul>
		</div>
	</footer>
</template>

<script>
export default {
	name: "Footer",
};
</script>

<style lang="scss">
@import "@/styles/variables";
@import "@/styles/mixins";

.footer {
	padding: 8rem 0;
	background: $grey4;
	color: $white;

	@include color-scheme(dark) {
		background: $grey0;
	}

	@media (min-width: $bp-md) {
		padding: 16rem 0;
	}

	a {
		color: inherit;
	}
}

.footer__brand {
	&__copyright {
		@include text-size($font-size-small, "strict");
	}
}

.footer__nav {
	@media (max-width: $bp-sm-max) {
		margin-bottom: 2rem;
		padding-bottom: 2rem;
		@include pseudo-border(rgba($grey0, 0.2), "bottom");
	}

	ul {
		list-style: none;
    display: flex;
    flex-wrap: wrap;

		@media (min-width: $bp-md) {
			display: block;
		}

		li {
			display: block;
			flex-basis: 50%;

			a {
				@include link-underline(true);
				display: block;
				padding: .5rem 0;
				margin-bottom: 1rem;
				
				@media (min-width: $bp-md) {
					margin: 0;
				}
			}

			svg {
				margin-right: 1rem;

				path {
					fill: $primary;
				}
			}
		}
	}
}

.footer__toggles {
	@media (max-width: $bp-sm-max) {
		margin-bottom: 2rem;
		padding-bottom: 2rem;

		@include pseudo-border(rgba($grey0, 0.2), "bottom");
	}

	.switch {
		margin-right: 1rem;
	}
	> div {
		margin-bottom: 1rem;
	}

	.footer__toggles__info {
		padding-left: 1rem;

		button {
			@include text-size($font-size-base);
			padding: 0 1rem;
			opacity: 0.75;
			transition: opacity 0.2s ease;

			&:hover {
				opacity: 1;
			}
		}
	}
}
</style>
