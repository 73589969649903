<template>
		<div class="project-page__links">
			<div class="container">
				<div class="row row-xs-center">
					<div
						v-for="(link, index) in links"
						:key="index"
						class="col-xs-12 col-md-narrow"
					>
						<div v-if="typeof link.url === 'string'">
							<a :href="link.url" target="_blank" rel='noreferrer' class="btn" :class="{ 'btn--outline': index > 0 } ">
								{{ link.label }}
								<font-awesome-icon icon="external-link-alt" />
							</a>

						<div v-if="link.url.includes('euroconsumers.org')">
							<button @click="this.$refs.modalPrototypesInfo.openModal()" class="project-page__links__info-trigger"><font-awesome-icon icon="question-circle" /> About prototypes</button>

							<modal ref="modalPrototypesInfo">
								<template v-slot:body>
									<div>
										<h2 class="font-larger font-bold">
											About prototypes
										</h2>
										<div v-html="prototypeInfo"></div>
									</div>
								</template>
							</modal>
							</div>
						</div>

						<div v-else>
							<button
								@click="$refs[`modalPrototypes${index}`].openModal()"
								class="btn" :class="{ 'btn--outline': index > 0 } "
							>
								{{ link.label }}
							</button>

							<Modal :ref="`modalPrototypes${index}`">
								<template v-slot:body>
									<div>
										<h2 class="font-larger font-bold">
											{{ link.label }}
										</h2>
										<button
											@click="this.showInfo = !this.showInfo"
											class="project-page__links__info-trigger"
										>
											<font-awesome-icon icon="question-circle" />
											About prototypes
										</button>
										<transition name="slide">
											<div
												class="project-page__links__info-panel"
												v-if="showInfo"
											>
												<div v-html="prototypeInfo"></div>
											</div>
										</transition>
									</div>
									<ul>
										<li v-for="link in link.url" :key="link.label">
											<a
												:href="link.url"
												target="_blank"
												rel="noreferrer"
												class="project-page__links__listed-link"
											>
												<font-awesome-icon icon="chevron-right" />
												{{ link.label }}
												<font-awesome-icon icon="external-link-alt" />
											</a>
										</li>
									</ul>
								</template>
							</Modal>
						</div>
					</div>
				</div>
			</div>
		</div>
</template>

<script>
import Modal from "@/components/Modal";

export default {
	name: "Links",
	components: {
		Modal,
	},
	props: {
		links: {
			type: Array
		}
	},
	data() {
		return {
			showInfo: false,
			prototypeInfo: `<p>The prototype pages referenced in this website are part of Euroconsumers Pattern Library, a platform primarily for internal use of Euroconsumers development teams.</p>
											<p>The aim of this environment is to showcase structure, style and in-page dynamic behaviors: all the data and copy contained within is mocked and they <strong>do not</strong> feature any backend architecture (with the exception of mocked endpoints where needed).</p>
											<p>Every page is to be considered as a stand-alone static prototype, and <strong>every link or reference to other pages is deliberately made non-functional</strong>.</p>`,
		};
	},
	methods: {},
	mounted() {},
};
</script>

<style lang="scss">
@import "@/styles/variables";
@import "@/styles/mixins";

.project-page__links {
	text-align: center;
	background-color: $grey1;
	padding: 8rem 0;

	@media (min-width: $bp-md) {
		padding: 16rem 0;
	}

	.btn {
		display: table;
		margin: auto;
		white-space: nowrap;
		margin-bottom: 2rem;

		@media (min-width: $bp-md) {
			display: inline-block;
		}
	}

	.project-page__links__info-trigger {
		@include text-size($font-size-smaller);
		top: -1rem;
		position: relative;
		background-color: transparent;
		color: $grey3;
		padding: 0 2rem;
		line-height: 3rem;
		border-radius: 5rem;
		@include pseudo-border($grey2);
		margin-bottom: 1rem;
		transition: 0.2s ease background-color, 0.2s ease color;

		&:after {
			transition: 0.2s ease border;
		}

		&:hover,
		&:focus {
			@include pseudo-border($primary);
			color: $primary;
		}
	}

	.project-page__links__info-panel {
		overflow: hidden;
		margin-top: -1rem;

		&.slide-enter-active,
		&.slide-leave-active {
			transition: max-height 0.3s ease, margin 0.15s ease, opacity 0.3s ease;
		}

		&.slide-enter-to,
		&.slide-leave-from {
			max-height: 70rem;
		}

		&.slide-enter-from,
		&.slide-leave-to {
			max-height: 0;
			margin-top: 0;
			opacity: 0;
		}

		> div {
			background-color: $grey1;
			border-radius: 1rem;
			padding: 1rem 2rem;
			@include text-size($font-size-small);
			position: relative;
			margin-bottom: 2rem;
			margin-top: 1rem;

			@media (min-width: $bp-md) {
				padding: 2rem 4rem;
				margin-bottom: 4rem;
			}

			&:before {
				content: "";
				position: absolute;
				left: 10rem;
				bottom: 100%;
				border: solid transparent;
				height: 0;
				width: 0;
				pointer-events: none;
				border-color: transparent;
				border-bottom-color: $grey1;
				border-width: 1rem;
				margin-left: -1rem;
			}
		}
	}

	.project-page__links__listed-link {
		padding: 1rem;
		color: $text-color;
		@include link-underline(never);
		display: block;
		border-radius: 1rem;
		transition: background-color 0.2s ease;

		&:focus,
		&:hover {
			background-color: $grey1;
		}

		svg {
			&:first-child {
				font-size: 2rem;
				color: $primary;
				margin-right: 1rem;
			}
			&:last-child {
				font-size: 1.5rem;
				margin-left: 1rem;
				opacity: 0.75;
			}
		}
	}
}

</style>