<template>
	<section class="highlight" id="top">
		<div class="container">
			<div class="row">
				<div class="col-xs-12 col-md-6">
					<figure
						class="highlight__image"
						tabindex="0"
						@click="animatePortrait()"
						@keyup.enter="animatePortrait()"
						ref="portrait"
					>
						<img
							src="@/assets/portrait-face.svg"
							class="highlight__image__face"
							width="480"
							height="480"
							alt=""
						/>
						<div class="js-animation highlight__image__cup">
							<img src="@/assets/portrait-cup.svg" alt="" loading="lazy" />
						</div>
						<div class="js-animation highlight__image__beer">
							<img src="@/assets/portrait-beer.svg" alt="" loading="lazy" />
						</div>
						<div class="js-animation highlight__image__earbuds">
							<img
								src="@/assets/portrait-earbuds-music.svg"
								class="music"
								alt=""
								loading="lazy"
							/>
							<img
								src="@/assets/portrait-earbuds.svg"
								class="buds"
								alt=""
								loading="lazy"
							/>
							<img
								src="@/assets/portrait-ears-mask.svg"
								class="mask"
								alt=""
								loading="lazy"
							/>
							<img
								src="@/assets/portrait-earbuds-hands.svg"
								class="hands"
								alt=""
								loading="lazy"
							/>
						</div>
						<figcaption>
							Vector art self-portrait I made with Adobe Illustrator
						</figcaption>
					</figure>
				</div>
				<div class="col-xs-12 col-md-6">
					<h1 class="highlight__title">
						Hello!
						<br />
						I'm
						<strong>Riccardo</strong>,<wbr />
						I design and build web things
					</h1>
					<p class="highlight__subtitle">
						I'm a self-taught front-end developer
						<wbr />
						and a lifelong designer, based in Milan.
					</p>
				</div>
			</div>
		</div>

		<div class="highlight__bg" aria-hidden="true">
			<div v-if="this.$root.theme == 'dark'" class="northern-lights">
				<span v-for="i in this.$root.isMd ? 20 : 4" :key="i"></span>
			</div>
			<div class="highlight__bg__swoosh"></div>
		</div>
	</section>
</template>

<script>
export default {
	name: "Highlight",
	props: {},
	data() {
		return {
			animationActive: false,
		};
	},
	methods: {
		animatePortrait(e) {
			const self = this;
			if (this.animationActive == true) return false;
			const candidates = this.$refs.portrait.querySelectorAll(".js-animation");
			this.animationActive = true;
			candidates.forEach((el) => {
				el.classList.remove("active");
			});
			const el = candidates[Math.floor(Math.random() * candidates.length)];
			setTimeout(function () {
				el.classList.add("active");
			}, 0);
			setTimeout(function () {
				self.animationActive = false;
			}, 6000);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/styles/variables";
@import "@/styles/mixins";

$swoosh-light: rgba(#ffffff, 0.999);
$swoosh-dark: rgba(#19202a, 0.999);
$swoosh-path-desktop: "%3Cpath d='M1440,288h-31.1C1428.5,288.1,1440,288,1440,288z'/%3E%3Cpath class='st0' d='M480,101.3C320,85,160,139,80,165.3L0,192v96h1408.9c-83.8-0.5-316.9-5.4-448.9-42.7C800,203,640,117,480,101.3 z'/%3E";
$swoosh-path-mobile: "%3Cpath class='st0' d='M0,0c190,0,199,286.6,454,286.6v1.5H0V0z'/%3E";
$swoosh-path-bg: "%3Cpath class='st0' d='M1002.7,33.8C674.7,33.8,632,288,0,288h2005.4C1373.4,288,1330.7,33.8,1002.7,33.8z'/%3E";

@function swoosh($path, $width, $height, $color) {
	$svg: "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 " +
		$width + " " + $height + "' style='enable-background:new 0 0 " + $width +
		" " + $height +
		"' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:" + $color +
		";%7D%0A%3C/style%3E" + $path + "%3C/svg%3E%0A";
	@return $svg;
}

.highlight {
	position: relative;
	background: transparent;
	padding: 10rem 0 8rem;
	z-index: 1;
	color: $text-color;

	@media (min-width: $bp-md) {
		color: $white;
	}

	@media (min-width: $bp-md) {
		padding: 24rem 0 16rem;
	}
}

.highlight__bg {
	position: absolute;
	overflow: hidden;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: -1;
	background-image: $bg-gradient;
	aspect-ratio: initial;
	bottom: auto;
	height: 44rem;
	padding-bottom: 0;

	@media (min-width: $bp-sm) {
		aspect-ratio: initial;
		bottom: auto;
		height: 50rem;
		padding-bottom: 0;
	}

	@media (min-width: $bp-md) {
		height: 62rem;
	}

	@media (min-width: $bp-lg) {
		height: 90rem;
	}

	&:after,
	&:before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		background-image: url(swoosh($swoosh-path-bg, 2005, 288, $swoosh-light));
		background-size: cover;
		background-repeat: repeat-x;
		background-position: bottom center;
		transform-origin: bottom center;
		background-size: 50% auto;
		width: 1000%;
		z-index: -1;

		//ff fix for ignored opacity. doesn't make sense but it works (forces re-render on breakpoint maybe?)
		border-top: 1px solid transparent;

		@include color-scheme(dark) {
			display: none;
		}

		@media (min-width: $bp-sm) {
			background-size: 50% auto;
			width: 600%;
		}

		@media (min-width: $bp-md) {
			width: 500%;
		}

		@media (min-width: $bp-lg) {
			width: $container-lg * 8;
		}
	}

	&:before {
		opacity: 0.1;
		background-position-x: 100%;
		animation: move-wave-mobile 25s linear infinite;
		//animation-delay: 2s;

		@media (min-width: $bp-sm) {
			animation-name: move-wave-desktop;
			animation-duration: 35s;
			opacity: 0.15;
		}

		@media (prefers-reduced-motion) {
			animation: none;
		}
	}

	&:after {
		opacity: 0.05;
		animation: move-wave-mobile 35s linear infinite;

		@media (min-width: $bp-sm) {
			animation-name: move-wave-desktop;
			animation-duration: 65s;
			opacity: 0.06;
		}

		@media (prefers-reduced-motion) {
			animation: none;
		}
	}

	.highlight__bg__swoosh {
		position: absolute;
		overflow: hidden;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		z-index: 1;
		height: 100%;
		background-image: url(swoosh($swoosh-path-mobile, 454, 288, $swoosh-light));
		background-size: 100% auto;
		background-repeat: no-repeat;
		background-position: right 0 bottom -1px;
		filter: drop-shadow(0px 0px 8rem $primary);

		@include color-scheme(dark) {
			background-image: url(swoosh(
				$swoosh-path-mobile,
				454,
				288,
				$swoosh-dark
			));
		}

		@media (min-width: $bp-sm) {
			aspect-ratio: initial;
			bottom: auto;
			padding-bottom: 0;
			background-image: url(swoosh(
				$swoosh-path-desktop,
				1440,
				288,
				$swoosh-light
			));

			@include color-scheme(dark) {
				background-image: url(swoosh(
					$swoosh-path-desktop,
					1440,
					288,
					$swoosh-dark
				));
			}
		}
	}
}

.highlight__image {
	position: relative;
	width: 30rem;
	margin: auto;
	margin-bottom: 4rem;
	display: flex;
	align-items: center;
	justify-content: center;
	aspect-ratio: 1/1;
	background-color: $primary4;
	border-radius: 50%;
	overflow: hidden;
	cursor: pointer;

	@include color-scheme(dark) {
		background-color: $grey1;
	}
		
	@media (prefers-reduced-motion) {
		pointer-events: none;
	}


	@media (min-width: $bp-smaller) {
		width: 35rem;
	}

	@media (min-width: $bp-sm) {
		width: 40rem;
	}

	@media (min-width: $bp-md) {
		margin-bottom: 0;
	}

	@media (min-width: $bp-lg) {
		margin-bottom: 0;
		width: 60rem;
	}

	img {
		display: block;
		max-height: 100%;
		margin: auto;
		height: 100%;
		width: 100%;
		object-fit: contain;
	}

	> div {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	figcaption {
		position: absolute;
		width: 0;
		height: 0;
		overflow: hidden;
		opacity: 0;
	}

	/* 	.highlight__image__face {
		//position: static;
	} */

	.highlight__image__ears {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
	}

	.highlight__image__cup {
		left: 0;
		top: 0;
		transform: translate3d(0, 100%, 0);

		&.active {
			animation: sip 6s cubic-bezier(0.6, 0.15, 0.42, 0.94) 1;
		}
	}

	.highlight__image__beer {
		left: 0;
		top: 0;
		transform: translate3d(0, 100%, 0);

		&.active {
			animation: sip 6s cubic-bezier(0.6, 0.15, 0.42, 0.94) 1;
		}
	}

	.highlight__image__earbuds {
		left: 0;
		top: 0;

		img {
			position: absolute;
			top: 0;
			left: 0;
			transform: translate3d(0, 100%, 0);
			&.music {
				opacity: 0;
				transform: none;
			}
			&.mask {
				display: none;
				transform: none;
			}
		}

		&.active img {
			&.hands {
				animation: hands 6s ease 1;
			}
			&.buds {
				animation: buds 6s ease 1;
			}
			&.music {
				animation: music 6s ease 1;
			}
			&.mask {
				display: block;
			}
		}
	}

	@keyframes sip {
		0% {
			transform: translate3d(0, 100%, 0);
		}
		35% {
			transform: none;
		}
		65% {
			transform: none;
		}
		100% {
			transform: translate3d(0, 100%, 0);
		}
	}

	@keyframes hands {
		0% {
			transform: translate3d(0, 100%, 0);
		}
		15% {
			transform: none;
		}
		50% {
			transform: translate3d(0, 100%, 0);
		}
		85% {
			transform: none;
		}
		100% {
			transform: translate3d(0, 100%, 0);
		}
	}

	@keyframes buds {
		0% {
			transform: translate3d(0, 100%, 0);
		}
		15% {
			transform: none;
		}
		85% {
			transform: none;
		}
		100% {
			transform: translate3d(0, 100%, 0);
		}
	}

	@keyframes music {
		15% {
			opacity: 0;
		}
		17% {
			opacity: 1;
		}
		85% {
			opacity: 1;
		}
		87% {
			opacity: 0;
		}
	}
}

.highlight__title {
	@include text-size($font-size-larger, "strict");
	font-weight: 900;
	margin-bottom: 2rem;
	color: inherit;

	@media (min-width: $bp-md) {
		@include text-size($font-size-largest, "strict");
	}

	@media (min-width: $bp-lg) {
		@include text-size(6rem, "strict");
	}

	strong {
		color: $primary;
		font-weight: inherit;

		@media (min-width: $bp-md) {
			color: $accent;

			@include color-scheme(dark) {
				color: $primary;
			}
		}
	}
}

.highlight__subtitle {
	@include text-size($font-size-large, "normal");
	font-weight: 700;
	color: inherit;

	@media (min-width: $bp-md) {
		@include text-size($font-size-large, "normal");
	}

	@media (min-width: $bp-lg) {
		@include text-size($font-size-larger, "normal");
	}

	a {
		color: inherit;
	}
}

@keyframes move-wave-desktop {
	0% {
		transform: translate3d(0, 0, 0) scale3d(1, 0.6, 1);
	}
	50% {
		transform: translate3d(-25%, 0, 0) scale3d(1, 1.5, 1);
	}
	100% {
		transform: translate3d(-50%, 0, 0) scale3d(1, 0.6, 1);
	}
}

//firefox mobile performs poorly with scale transformation, so keeping it constant
@keyframes move-wave-mobile {
	0% {
		transform: translate3d(0, 0, 0) scale3d(1, 0.6, 1);
		-moz-transform: translate3d(0, 0, 0) scale3d(1, 1.4, 1);
	}
	50% {
		transform: translate3d(-25%, 0, 0) scale3d(1, 1.5, 1);
		-moz-transform: translate3d(-25%, 0, 0) scale3d(1, 1.4, 1);
	}
	100% {
		transform: translate3d(-50%, 0, 0) scale3d(1, 0.6, 1);
		-moz-transform: translate3d(-50%, 0, 0) scale3d(1, 1.4, 1);
	}
}
</style>
