import { createApp } from 'vue'
import App from './App.vue';
import router from '@/router';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { faBars, faTimes, faUniversalAccess, faFingerprint, faDraftingCompass, faTag, faSignal, faSwatchbook, faLightbulb, faBorderAll, faCaretRight, faExternalLinkAlt, faQuestionCircle, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faLinkedin, faCodepen } from '@fortawesome/free-brands-svg-icons';

library.add(faBars,faTimes, faUniversalAccess, faFingerprint, faDraftingCompass, faTag, faSignal, faSwatchbook, faLightbulb,faBorderAll, faCaretRight, faExternalLinkAlt, faQuestionCircle, faChevronLeft, faChevronRight, faInstagram, faLinkedin, faCodepen);

createApp(App).component("font-awesome-icon", FontAwesomeIcon).use(router).mount('#app')
