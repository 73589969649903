<template>
<div>
	<Highlight />
	<About />
	<Skills :list="this.$root.tech" />
	<Knowledge :list="this.$root.philosophy" />
	<ProjectsList :list="this.$root.projects" />
	</div>
</template>

<script>
import Highlight from "@/components/Highlight.vue";
import About from "@/components/About.vue";
import Skills from "@/components/Skills.vue";
import Knowledge from "@/components/Knowledge.vue";
import ProjectsList from "@/components/ProjectsList.vue";

export default {
	name: "Home",
	components: {
		Highlight,
		About,
		Skills,
		Knowledge,
		ProjectsList,
	},
};
</script>

