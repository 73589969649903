<template>
	<section class="knowledge" id="knowledge">
		<div class="container">
			<header class="knowledge__title">
				<h2 class="knowledge__title__sup">Knowledge & Philosophy</h2>
				<p class="knowledge__title__main">My design and development approach</p>
			</header>

			<div v-if="!$root.isMd" aria-roledescription="carousel">
				<div class="knowledge__nav" role="tablist" aria-label="Knowledge slides" ref="carouselNav">
						<div v-for="card in this.list" :key="card.name">
							<button
								@click="changeSlide(list.indexOf(card))"
								:class="{ active: list.indexOf(card) == currentSlide }"
								class="knowledge__icon"
								:aria-selected="list.indexOf(card) == currentSlide ? true : false" :aria-controls="card.id" role="tab"
							>
								<font-awesome-icon :icon="card.icon" />
								<span class="visuallyhidden">{{card.name}}</span>
							</button>
						</div>
				</div>
				<div aria-live="polite" ref="carousel">
				<swiper
					v-if="!$root.isMd"
					@swiper="onSwiper"
					@slideChangeTransitionEnd="onSlideChange"
					:slides-per-view="1.3"
					:loop="true"
					:centeredSlides="true"
					class="knowledge__carousel"
				>
					<swiper-slide
						class="knowledge__card"
						v-for="card in this.list"
						:key="card.id"
						role="tabpanel"
						aria-roledescription="slide"
						:aria-label="list.indexOf(card) + 1 + ' / ' + list.length"
					>
					<div>
						<h3 class="knowledge__card__title">{{ card.name }}</h3>
						<div v-html="card.text"></div>
						</div>
					</swiper-slide>
				</swiper>
				</div>
			</div>

			<div class="row" v-if="$root.isMd">
				<div
					class="col-xs-12 col-md-4"
					v-for="card in this.list"
					:key="card.name"
				>
					<div class="knowledge__card">
						<div class="knowledge__icon">
							<font-awesome-icon :icon="card.icon" />
						</div>
						<h3 class="knowledge__card__title">{{ card.name }}</h3>
						<div v-html="card.text"></div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import waypoint from "@/mixins/waypoint.js";
import keyboard from "@/mixins/keyboard.js";
import { Swiper, SwiperSlide } from "swiper/vue";
//import SwiperCore, { Navigation } from "swiper";
//SwiperCore.use([Navigation]);
import "swiper/swiper.scss";
//import "swiper/components/navigation/navigation.scss";
//import "swiper/components/a11y/a11y.scss";

export default {
	name: "Knowledge",
	components: {
		Swiper,
		SwiperSlide,
	},
	props: {
		list: undefined,
	},
	mixins: [waypoint, keyboard],
	data() {
		return {
			currentSlide: 0,
		};
	},
	mounted() {
		this.waypoint(this.$el, 0.4, () => {
			this.$el.classList.add("enter");
		});

		if (!this.$root.isMd) {
			//add IDs to mobile slides (can't include them in template or they'll be duplicated by swiper)
			const slides = this.$refs.carousel.querySelectorAll('.swiper-slide');
			for (let slide of slides) {
				if (!slide.classList.contains('swiper-slide-duplicate')) {
					const i = slide.dataset.swiperSlideIndex;
					slide.setAttribute('id', this.list[i].id);
				}
			}

			this.tablist(this.$refs.carouselNav);
		}
	},
	methods: {
		onSwiper(swiper) {
			this.swiper = swiper;
		},
		onSlideChange(swiper) {
			this.currentSlide = swiper.realIndex;
			this.toggleTab(this.$refs.carousel.querySelectorAll('.knowledge__card'), false)
			this.toggleTab(this.$refs.carousel.querySelectorAll('.knowledge__card.swiper-slide-active'), true)
		},
		changeSlide(i) {
			this.currentSlide = i;
			this.swiper.slideTo(this.currentSlide + 2);
		},
	},
};
</script>

<style lang="scss">
@import "@/styles/variables";
@import "@/styles/mixins";

.knowledge {
	padding: 8rem 0 4rem;

	@media (min-width: $bp-md) {
		padding: 24rem 0 8rem;
	}

	@media (max-width: $bp-sm-max) {
		@include pseudo-border($primary, "bottom", $opacity: 0.5);
	}
}

.knowledge__title {
	margin-bottom: 4rem;

	@media (min-width: $bp-md) {
		margin-bottom: 8rem;
	}

	&__sup {
		@include text-size($font-size-small);
		font-weight: 900;
		text-transform: uppercase;
		color: $primary;
		margin-bottom: 1rem;

		@include color-scheme(dark) {
			color: $primary;
		}

		@media (min-width: $bp-md) {
			margin-bottom: 2rem;
		}
	}
	&__main {
		@include text-size($font-size-largest);
		font-weight: 700;

		@media (min-width: $bp-md) {
			@include text-size($font-size-largest);
		}
	}
}

.knowledge__carousel {
	margin: 0 #{-$container-padding};

	@media (min-width: $bp-md) {
		margin: 0;
	}
}

.knowledge__card {
	margin-bottom: 4rem;
	max-width: 100%;
	padding: 0 2rem;

	@media (min-width: $bp-md) {
		margin-bottom: 8rem;
		padding: 0;
	}

	&__title {
		@include text-size($font-size-large);
		font-weight: 700;
		margin-bottom: 1rem;
	}
}

.knowledge__nav {
	margin-bottom: 4rem;
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;

	div {
		flex-grow: 0;
		text-align: center;
	}
}

.knowledge__icon {
	display: inline-block;
	font-size: 4rem;
	line-height: 4rem;
	vertical-align: bottom;
	transform: scale3d(0.1, 0.1, 1);
	transition: 0.35s ease transform, 0.35s ease opacity;
	opacity: 0;
	transition-delay: 0s;

	@media (prefers-reduced-motion) {
		opacity: 1;
		transform: none;
	}

	@for $i from 1 through 6 {
		.col-xs-12:nth-child(#{$i}) &,
		li:nth-child(#{$i}) & {
			transition-delay: #{0.1 + $i/7}s;
		}
	}

	@media (min-width: $bp-md) {
		margin-bottom: 2rem;
	}

	.enter & {
		transform: scale3d(1, 1, 1);
		opacity: 1;

		@media (min-width: $bp-md) {
			transform: none;
		}
	}

	svg {
		vertical-align: bottom;

		path {
			color: $primary;
			opacity: 0.35;
			transition: 0.2s ease opacity, 0.2s ease transform;
			transform: scale3d(0.8, 0.8, 1);
			transform-origin: bottom center;

			@at-root .active#{&} {
				opacity: 1;
				transform: none;
			}

			@media (min-width: $bp-md) {
				opacity: 1;
				transform: none;
			}
		}
	}
}


</style>
