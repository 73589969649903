<template>
	<div class="about" id="about">
		<div class="container container-md">
			<div class="about__panel">
				<div class="row row-nogutter row-md-reverse row-md-middle">
					<div class="col-xs-12 col-md-4">
						<div class="about__image">
							<picture>
								<source
									:media="`(min-width: ${$root.bpMd}px)`"
									srcset="@/assets/about-d.webp"
									width="268"
									height="400"
								/>
								<img
									srcset="@/assets/about-m@2x.webp 2x, @/assets/about-m@1x.webp 1x"
									width="450"
									height="225"
									alt="Me freezing in front of the icelandic landscape"
									loading="lazy"
								/>
							</picture>
						</div>
					</div>
					<div class="col-xs-12 col-md-8">
						<div class="about__content">
							<p>
								My journey started as a graphic designer more than 20 years ago. Technology and the internet have always been my passion and I decided to take the leap to web design around 2008,
								when I started working at the consumer association <a href="https://www.altroconsumo.it" target="_blank" rel="noreferrer">Altroconsumo</a>, part of the <a href="https://www.euroconsumers.org" target="_blank" rel="noreferrer">Euroconsumers group</a>.
							</p>
							<p>
								I have progressively shifted my focus towards development since then, and I am now involved as front-end developer in several Euroconsumers projects.
							</p>
							<p>
								I draw comics and listen to unpleasant music in my spare time. Hit me up on:
							</p>
							<div class="about__content__icons">
								<a href="https://www.linkedin.com/in/riccardofarinadev/" target="_blank" rel="noreferrer"><font-awesome-icon :icon="['fab','linkedin']" /><span class="visuallyhidden">Linkedin</span></a>
								<a href="https://www.instagram.com/many_zombies/"  target="_blank" rel="noreferrer"><font-awesome-icon :icon="['fab','instagram']" /><span class="visuallyhidden">Instagram</span></a>
								<a href="https://codepen.io/zombiehype"  target="_blank" rel="noreferrer"><font-awesome-icon :icon="['fab','codepen']" /><span class="visuallyhidden">Codepen</span></a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>


export default {
	name: "About",
};
</script>

<style lang="scss">
@import "@/styles/variables";
@import "@/styles/mixins";

.about {
	position: relative;
	z-index: 2;
	margin-bottom: -4rem;

	@media (min-width: $bp-md) {
		margin: -8rem 0 -4rem;
	}
}

.about__panel {
	box-shadow: $shadowXLarge;
	border-radius: 2rem;
	background-color: $bg-color;
	overflow: hidden;
	margin: 0 -2rem;

	@media (min-width: $bp-md) {
		margin: 0;
	}

	@include color-scheme(dark) {
		box-shadow: $glowLarge;
		@include pseudo-border($primary);
		&:after {
			opacity: 0.5;
		}
	}
}
.about__image {
	img {
		display: block;

		@media (min-width: $bp-md) {
			height: 50rem;
		}
	}
}
.about__content {
	padding: 4rem 4rem 6rem;

	@media (min-width: $bp-md) {
		padding: 4rem;
		text-align: right;
	}

	p:not(:last-child) {
		margin-bottom: 1rem;
	}

	.about__content__title {
		@include text-size($font-size-larger, strict);
		font-weight: 900;
		margin-bottom: 2rem;
		background-image: linear-gradient(to right, $primary, $primary3);
		background-clip: text;
		-webkit-text-fill-color: transparent;

		@include color-scheme(dark) {
			background-image: linear-gradient(to right, $primary, $primary4);
		}

		@media (min-width: $bp-md) {
			margin-bottom: 4rem;
		}
	}
}

.about__content__icons {
	text-align: center;
	line-height: 0;

	@media (min-width: $bp-md ){
		text-align: inherit;
	}

	a {
		display: inline-block;
		font-size: 4rem;
		padding: 1rem;
		border-radius: 50%;
		width: 6rem;
    height: 6rem;
    text-align: center;
		
		&:not(:last-child) {
			margin-right: 2rem;
		}
	}
}
</style>
