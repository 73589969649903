<template>
	<header class="header" v-bind:class="{ affix: this.$root.scroll > 50 }">
		<div class="container">
			<div class="header__content">
				<div class="header__brand">
					<router-link to="/#top"><img src="@/assets/logo.svg" alt="" /><span class="visuallyhidden">Homepage/top</span></router-link>
				</div>
				<nav class="header__menu" aria-label="main navigation">
					<div class="header__menu__trigger">
						<button
							@click="$refs.modalMenu.openModal()"
							type="button"
							title="open menu"
						>
							<font-awesome-icon icon="bars" />
						</button>
					</div>
					<modal ref="modalMenu" :active="!$root.isMd" cssClass="header__modal-menu">
						<template v-slot:body>
							<ul class="header__menu__list">
								<li>
									<router-link
										to="/#about"
										@click="$refs.modalMenu.closeModal()"
									>
										About
									</router-link>
								</li>
								<li>
									<router-link
										to="/#skills"
										@click="$refs.modalMenu.closeModal();"
									>
										Tech
									</router-link>
								</li>
								<li>
									<router-link
										to="/#knowledge"
										@click="$refs.modalMenu.closeModal()"
									>
										Knowledge
									</router-link>
								</li>
								<li>
									<router-link
										to="/#projects"
										@click="$refs.modalMenu.closeModal()"
									>
										Projects
									</router-link>
								</li>
								<li>
									<button
										@click="this.$root.toggleTheme()"
										v-bind:class="{ active: this.$root.theme == 'light' }"
										title="Toggle dark mode"
									>
										<font-awesome-icon icon="lightbulb" />
										<span class="switch"></span>
									</button>
								</li>
								<li>
									<button
										@click="this.$root.toggleGrid()"
										v-bind:class="{ active: this.$root.grid == 'on' }"
										title="Toggle grid"
									>
										<font-awesome-icon icon="border-all" />
										<span class="switch"></span>
									</button>
									<div class="header__menu__list__info">
									<button title="What's this?" @click="$refs.modalMenu.closeModal(); this.$root.$refs.modalGrid.openModal()">
										<font-awesome-icon icon="question-circle" />
									</button>
									</div>
								</li>
							</ul>
						</template>
					</modal>
				</nav>
			</div>
		</div>
		<div class="header__bg" aria-hidden="true"></div>
	</header>
</template>

<script>
import Modal from "@/components/Modal";

export default {
	name: "Header",
	components: {
		Modal,
	},
	data() {
		return {
			showModal: false,
		};
	},
	mounted() {},
	methods: {},
};
</script>

<style lang="scss">
@import "@/styles/variables";
@import "@/styles/mixins";

.header {
	height: $header-heigh-mobile;
	z-index: 10;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	color: $white;
	transition: height 0.5s ease, color 0.2s ease;

	@media (prefers-reduced-motion) {
		transition-duration: 0.1s, 0.1s;
	}


	@media (min-width: $bp-md) {
		height: $header-heigh-desktop;
	}

	&:before {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: $grey0;
		background: var(--bg-gradient);
		opacity: 0;
		z-index: 0;
		transition: opacity 0.5s ease;
		box-shadow: $shadowMedium;

		@media (prefers-reduced-motion) {
			transition-duration: 0.1s;
		}

		@include color-scheme(dark) {
			box-shadow: $glowXLarge;
			background: linear-gradient(50deg, $primary 50%, $primary3 100%);
		}
	}

	&.affix {
		height: $header-heigh-mobile-affixed;

		@include color-scheme(dark) {
			color: $grey1;
		}

		@media (min-width: $bp-md) {
			height: $header-heigh-desktop-affixed;
		}

		&:before {
			opacity: 1;
		}
	}

	body.showing-modal & {
		margin-right: var(--scrollbarWidth);
	}
}

.header__content {
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: stretch;
	position: relative;
	z-index: 1;
}

.header__brand {
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		display: block;
		height: 3rem;

		@media (min-width: $bp-md) {
			height: 4rem;
		}
	}


}

.header__menu {
	display: flex;
	flex-direction: row;
	align-items: stretch;
	justify-content: center;
}

.header__menu__trigger {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	button {
		padding: 0 1rem;
		background-color: rgba($grey0, 0.2);
		border-radius: 2rem;
		width: 6rem;
		height: 4rem;
		line-height: 0;
	}

	svg {
		height: 3rem;
		width: 3rem;
		font-size: 3rem;

		path {
			transition: 1.5s ease fill;
			fill: $white;
			
			.affix & {
				@include color-scheme(dark) {
					fill: $grey1;
				}
			}
		}
	}

	@media (min-width: $bp-md) {
		display: none;
	}
}

.header__menu__list {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: center;

	@media (min-width: $bp-md) {
		flex-direction: row;
	}

	li {
		display: flex;
		align-items: stretch;
		justify-content: flex-end;
		&:not(:last-child) {
			@include pseudo-border($grey1, "bottom");
		}

		@media (min-width: $bp-md) {
			justify-content: center;
			&:after {
				display: none;
			}
		}

		a,
		button {
			display: flex;
			padding: 0 2rem;
			height: 5rem;
			border-radius: 0.5rem;
			align-items: center;
			justify-content: center;
			@include link-underline(false);
			font-size: $font-size-base;
			font-weight: 700;
			color: $text-color;
			transition: 0.2s ease background-color;

			@media (min-width: $bp-md) {
				color: inherit;
				margin-left: 1rem;
				height: auto;

				&:hover,
				&:focus {
					background-color: rgba($grey4, 0.2);
				}
			}

			.switch {
				margin-left: 1rem;
			}
		}

		.header__menu__list__info {
			order: -1;
			justify-content: flex-start;

			button {
				padding-right: 0;

				svg {
					width: 2rem;
					height: 2rem;
					font-size: 2rem;
					}

				path {
					transition: opacity 0.2s ease;
					opacity: 0.75;
					}
			}

			@media (min-width: $bp-md) {
				order: 1;
				display: flex;
				align-items: center;

				button {
					padding: 1rem;
					margin: 0;
					}
			}

			&:hover {
				path {
					opacity: 1;
				}
			}
		}
	}
}

.header__modal-menu {
	.modal__dialog {
		margin: 1rem;
		transform-origin: right top;
	}
	}
</style>
