<template>
<aside aria-label="Skip to content">
	<a class="skip-to-content btn" href="#main">
		Skip to content
	</a>
	</aside>
	<Header />
	<main id="main">
		<router-view :key="$route.path" />
	</main>
	<Footer />

	<modal ref="modalGrid" active="active">
		<template v-slot:body>
			<h2 class="font-larger font-bold">
				<font-awesome-icon icon="border-all" class="text-lightest" />
				What's this grid thing?
			</h2>
			<p>
				<strong>Vertical rythm</strong>
				is an important principle in typography and design: it concerns the
				proportion between characters and line spacing, and helps creating an
				armonious structure for block of texts and headings.
			</p>
			<p>
				In webdesign, this principle can be applied to (nearly) any element in
				the page, not only texts, effectively conforming the whole UI to a
				<strong>
					modular increment that offers consistent and predictable heights
				</strong>
				.
			</p>
			<p>
				You can read more about vertical rythm in webdesign in this great
				<a
					href="https://www.smashingmagazine.com/2012/12/css-baseline-the-good-the-bad-and-the-ugly/"
					target="_blank" rel="noreferrer"
				>
					Smashing Magazine article
				</a>
				.
			</p>
			<p>
				Over the years I created my own set of tools to help me with the
				implementation of vertical rythm in my projects. Here's a
				<a href="https://codepen.io/zombiehype/pen/JjbEMGN" target="_blank" rel="noreferrer">
					pen showcasing the mixins and functions I developed
				</a>
				for this purpose.
			</p>
			<p>
				The grid toggle overlays lines with 8px gaps (the module I choose)
				between them, and serves as an
				<strong>easy proofing tool</strong>
				to ensure the vertical rythm is correctly implemented and uninterrupted.
			</p>
		</template>
	</modal>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Modal from "@/components/Modal.vue";

export default {
	name: "App",
	components: {
		Header,
		Footer,
		Modal,
	},
	data() {
		return {
			scroll: 0,
			scrolling: false,
			windowWidth: undefined,
			bpSm: 450,
			bpMd: 800,
			bpLg: 1080,
			theme: this.prefersTheme,
			grid: "off",
			tech: [
				{
					name: "HTML5",
					text: `<p>A well structured, semantic DOM is the backbone of any web project. Semanticity, accessbility and usability all start from a robust and meaningful markup. Observing and applying the intended semantics of native elements, regions and attributes are the indispensable foundations for a robust, crawlable, usable, accessible and future-proof page.</p>`,
					image: "code-html",
					id: 'skill-html',
					alt: "Few lines of HTML I wrote",
				},
				{
					name: "CSS/SASS",
					text: `<p>I've been experimenting with CSS for more than 20 years, and with SASS for half that time. In that time CSS changed deeply, becoming a versatile and powerful language on its own (with native variables!), but I still find SASS an extremely convenient tool to build complex design systems. Functions and mixins are the real strenght of SASS that allow elegant and easily manageable design solutions.</p>`,
					image: "code-css",
					id: 'skill-css',
					alt: "Few lines of SASS I wrote",
				},
				{
					name: "Javascript",
					text: `<p>I eased into javascript during my journey from designer to developer. What started as a response to the frustration of not being able to perform certain tasks with native tools eventually tipped my career to the development side, and opening the gate for a more holistic approach to managing projects and workflows.</p>`,
					image: "code-js",
					id: 'skill-js',
					alt: "Few lines of js I wrote",
				},
				{
					name: "Vue.js",
					text: `<p>I started using Vue with the goal of furthering my knowledge of modern frameworks, and immediately fell in love with it. The separation of structure, style and functionality matches exactly my approach to development, while the convenience of having all three aspects nicely bundled and strictly correlated within the same file allows a neat and performant management of the workflow.</p>`,
					image: "code-vue",
					id: 'skill-vue',
					alt: "Few lines from a Vue project I wrote",
				},
				{
					name: "jQuery",
					text: `<p>While being on the tail end of web innovation, jQuery is still used by an <a href="https://trends.builtwith.com/javascript/jQuery" target="_blank" rel="noreferrer">astounding percentage</a> of products. I have several years worth of experience with this framework, ranging from simple in-page apps to complex widgets managing a variety of UI implementations across multiple platforms.</p>`,
					image: "code-jquery",
					id: 'skill-jquery',
					alt: "Few lines of jQuery I wrote",
				},
				{
					name: "Web-design",
					text: `<p>Being a graphic designer by training, Photoshop and Illustrator were among the first software I started using professionally almost 20 years ago. When my focus gradually shifted towards development, my design training has organically integrated in my work. Nowadays I mainly use <strong>Balsamiq</strong>, <strong>Figma</strong> and <strong>Photoshop</strong> for sketching and refining designs and mockups.</p>`,
					image: "sw-ps",
					id: 'skill-webdes',
					alt: "A screenshot from a design I worked on with Adobe Photoshop",
				},
				{
					name: "Graphic design",
					text: `<p>Branding drives most of the design choices I make for a new web project. It doesn't only include logo creation, fonts and colors, but involves many aspects of the design process: from page structure to interactions and content choices, everything contribute to communicating the project values to the user.</p>`,
					image: "sw-logo",
					id: 'skill-brand',
					alt: "A screenshot from a logo design I worked on with Adobe Illustrator",
				},
				{
					name: "Illustration",
					text: `<p>My main activity besides development is drawing. Over time this integrated seamlesly with my passion for design and my work as developer, allowing me to create illustrations (vector or otherwise) for some of my projects. Check out <a href='https://www.instagram.com/many_zombies/' target='_blank' rel="noreferrer">my instagram</a> for some of my works as comic artist.</p>`,
					image: "sw-face",
					id: 'skill-art',
					alt: "A screenshot from an illustration I designed in Adobe Illustrator",
				},
			],
			philosophy: [
				{
					name: "Accessbility",
					text: `<p>Making my work fully accessible is always my priority. While sensible design choices (respecting contrast requirements, hotspot sizes, text reflow) are indispensable, I believe machine-readability and good practices (like the meaningful use of elements and attributes) are the foundations of a truly accessible and future-proof product.</p>`,
					id: "a11y",
					icon: "universal-access",
				},
				{
					name: "Usability",
					text: `<p>Usability tenets drive every choice I make during the design process. I focus in particular on <a href="https://www.interaction-design.org/literature/topics/affordances" target="_blank" rel="noreferrer">affordances</a> and <a href="https://www.nngroup.com/articles/user-control-and-freedom/" target="_blank" rel="noreferrer">controllability</a>: it's important to me that every pattern and piece of UI looks familiar even to a first time user, so that its behavior can be recognized and predicted.</p>`,
					id: "usability",
					icon: "fingerprint",
				},
				{
					name: "Semanticity",
					text: `<p>I plan my design with a strict separation of content and presentaion in mind, which is the baseline for building an all-around accessible product. Respecting and leveraging the semantic meaning of all native HTML elements, regions and attributes is the fundamental first step in it.</p>`,
					id: "semanticity",
					icon: "drafting-compass",
				},
				{
					name: "Progressive enhancmenet",
					text: `<p>Progressive enhancement is the development approach that allows me to plan for accessibility and usability. Providing a baseline experience for all users is the core of accessibility, while enhanching features for the systems that support them is what builds a compelling user experience.</p>`,
					id: "pe",
					icon: "signal",
				},
				{
					name: "Naming conventions",
					text: `<p>Working with a large codebase used by several teams taught me that agreeing and adhering on a set of conventions is extremely important, and if planned in advance can save the developers many headaches down the road. <a href="http://getbem.com/naming/" target="_blank" rel="noreferrer">BEM</a> is the most popular one I currently use for CSS/SASS</p>`,
					id: "conventions",
					icon: "tag",
				},
				{
					name: "Design library",
					text: `<p>Managing a large ecosystem of multiple platforms with different teams would be impossible without a "rulebook". I believe implementing a shared library for all developers to use helps upholding conventions, consistency and reusability even in deep, complex and "spread-out" ecosystems.</p>`,
					id: "library",
					icon: "swatchbook",
				},
			],
			projects: [
				{
					id: "portfolio",
					name: "Personal portfolio",
					abstract: `<p>I decided to create my personal portfolio manily as a training ground to teach myself Vue.js, which I had just discovered and quickly grew to love. This has been my first real project in vue, and the first "personal" small-scale activity in quite some time.</p>`,
					color: "#ba425e",
					gallery: [
						{
							desktop: "portfolio1",
							mobile: "portfolio1m",
							id: "01",
						}
					],
					stack: [
						"Vue.js",
						"SASS/css3",
						"Swiper",
						"Fontawesome",
						"Firebase"
					],
					roles: [
						"Conception/Design",
						"Development",
						"Brand idendity",
						"Illustrations",
					],
					body: `<p>This was mainly a fun project I undertook to get myself comfortable with how Vue, and modern frameworks in general, work.</p>
					<p>It was the first solo project in a some time so I paid extra attention to all the aspects I have little control over in the context of bigger projects.</p>
					<p>I worked in particular to improve the pages perfomance in the Core Web Vitals, implement different techniques to minimize the cumulative layout shift (CLS) and optimize the overall perceived loading times. The effort was rewarded by a full Lighthouse score (100 in all categories in desktop when tested in production).</p>
					<p>I also tried to fully implement accessibility authoring practices in all components, following the <a href="https://www.w3.org/TR/2019/NOTE-wai-aria-practices-1.1-20190814/#aria_ex" rel="nofollow" target="_blank">WAI-ARIA official recommendations</a> for each interactive pattern.</p>
					<p>It was also a great opportunity to see <a href="https://codepen.io/zombiehype/pen/JjbEMGN" rel="nofllow" target="_blank">my baselining mixins and functions</a> in full swing: I applied the <a href="https://www.smashingmagazine.com/2012/12/css-baseline-the-good-the-bad-and-the-ugly/" rel="nofllow" target="_blank">vertical rythm approach</a> to all elements (including images, with a little js), something that I didn't have the opportunity to do  in so much detail before.</p>`,
				
				},
				{
					id: "lawyermap",
					name: "Lawyer selection map",
					abstract: `<p>Euroconsumers collaborates with a selection of law professionals to provide its members a bespoke legal consultancy. This web app allows the users to select the consultant(s) that best fit their needs, based on their area of expertise and location.</p>`,
					color: "#1e5ba3",
					gallery: [
						{
							desktop: "law1",
							mobile: "law1m",
							id: "01",
							caption:
								"<p>To ensure the user is never presented with an empty map in default view, the app polls larger and larger cardinal boundaries until the endpoint responds with at least one entry. Ajax calls triggered by user interaction (zoom, pan...) are managed by a customizable debounce function, to avoid chocking the endpoint with continuous requests.</p>",
						},
						{
							desktop: "law2",
							mobile: "law2m",
							id: "02",
							caption:
								"<p>Entities can change state (default/active/selected) triggered by user interaction within any component, and the change will propagate to all other components via custom event triggers. Clusters on the map will also reflect the state of the markers contained within.</p>",
						},
						{
							desktop: "law3",
							mobile: "law3m",
							id: "03",
							caption:
								"<p>The detail overlay is populated with data pulled with the initial request and passed from the maps component to the overlay component. When an entity is selected or removed by the user, its status changes in all components.</p>",
						},
						{
							desktop: "law4",
							mobile: "law4m",
							id: "04",
							caption:
								"<p><a href='https://design.euroconsumers.org/CaaS/get-help/step1-selection.html#noResults?lang=nl-be' target='_blank' rel='noreferrer'>Demoing an error case</a> in which the enpoint responds with no entities even after several attempts by the polling function at enlarging the cardinal boundaries. Once the boundaries reach a customizable maximum size, the polling stops and a blocking error message is displayed.</p>",
						},
					],
					stack: [
						"Liquid/Html5",
						"SASS/css3",
						"jQuery",
						"Widget factory",
						"Google Maps API",
						"Mockjax",
					],
					roles: [
						"Front-end dev",
					],
					body: `<p>This page is part of a journey the customer is giuded through in order to book a consultancy with a law professional. While being the first step of many, UX deemed important that it had an "native app" feel, especially for mobile users: this meant not only delivering an experience as seamless as possible, but solving few UI challenges that threatened to break the "illusion" of being in a web browser.</p>
					<p>The map component manages all the data exchange between the endpoint, google maps API and the front-end, while communicating the states of each entity to other components via custom event triggers. Responding to a specific UX requirement, the component also ensures the default map viewport is never empty, by polling larger and larger boundaries until the endpoint responds with at least one entity, before rendering the map proper via google maps API.</p>
					<p>As is standard procedure for my work with Euroconsumers, this project was delivered as a centralized solution to be implemented in five different countries, so special attention was paid to configurability/customizability of several parameters to fit the needs of widely different territories and markets.</p>
					<p>This prototype uses <a href="https://github.com/jakerella/jquery-mockjax" target="_blank" rel="noreferrer">mockjax</a> to simulate endpoint responses containing the entities location and details, based on the cardinal boundaries included in the front-end requests.</p>`,
					links: [
						{
							label: "View the prototype",
							url: "https://design.euroconsumers.org/CaaS/get-help/step1-selection.html?lang=nl-be",
						},
					],
				},
				{
					id: "mile21",
					name: "Mile21",
					abstract: `<p>MILE21 is a project co-funded by the European Union that aims to help consumers making well-informed purchase decisions for fuel efficient cars. It includes tools to track the consumption based on the users' vehicles and driving habits, and a webstore-like area with detailed information about cars currently on the market and their emissions.</p>`,
					gallery: [
						{
							desktop: "mile1",
							mobile: "mile1m",
							id: "01",
							caption: "<p>Due to its relative lack of editorial images and the modular look and feel of the UI, Mile21 was a great candidate for experimenting with my approach to <a href='https://www.smashingmagazine.com/2012/12/css-baseline-the-good-the-bad-and-the-ugly/' target='_blank' rel='noreferrer'>vertical rythm</a>. I eventually extrapolated the lessons I've learned in this regard in a set of <a href='https://codepen.io/zombiehype/pen/JjbEMGN' target='_blank' rel='noreferrer'>mixins and functions</a> that I continuosly improved on, and which I also used extensively to build this portfolio.</p>",
						},
						{
							desktop: "mile2",
							mobile: "mile2m",
							id: "02",
							caption: "<p>Users can manage their accounts on the platform, adding/removing cars and editing their driving styles, as well as track their refules. All these journeys are broken down in steps and presented as wizards that guide the user throught the process.</p>",
						},
						{
							desktop: "mile3",
							mobile: "mile3m",
							id: "03",
							caption: "<p>The 'Find a car' area is an e-commerce-like application with car models currently on the market and detail data about their consumption and emissions. Both the manifacturer's declared values and an estimation made by Mile21 are presented, to help the user with a well-informed choice. The prototype for this page uses Mockjax to simulate responses from and endpoint and present different mocked car models at each request.</p>",
						},
						{
							desktop: "mile4",
							mobile: "mile4m",
							id: "04",
							caption: "<p>The consumption area is the core of Mile21. In this personalized section, users can see their current consumption for each of their tracked cars, based on their driving style.</p>",
						},
						{
							desktop: "mile5",
							mobile: "mile5m",
							id: "05",
							caption: "<p>Users can also compare their current consumption for each car with the manifacturer's declared value, and the estimation made by Mile21. This gives an outline of how 'good' they are performing as a emissions-conscious driver and in relation to their active goals/challenges.</p>",
						},
						{
							desktop: "mile6",
							mobile: "mile6m",
							id: "06",
							caption: "<p>Refuels and mileage tracking can be viewed and managed from this page. Each record shows all the data the user entered at the time of tracking and is arranged as a intuitive freescrolling component on smaller viewports.</p>",
						},
					],
					color: "#67f4b4",
					stack: ["Liquid/Html5", "SASS/css3", "jQuery", "Widget factory", "Mockjax"],
					roles: [
						"Front-end dev",
					],
					body: `<p>Through the platform, users can create an account, register their cars and track their refuels in order to create a consumption profile. The data is used to provide an accurate analysis of the users consumption and compare it to the expected consumption declared by the car manifacturer.</p>
					<p>The site also provides a curated collection of advices under the for of challenges. After picking up a challenge, users can check the difference in consumption and emissions as a "saving" stat in their profiles</p>
					<p>A database of almost 1.5k car models feeds the car listing section. Each model is matched with detailed data about consumption and emissions, both as declared values and the realistic projection made by Mile21. Users can browse, filter and compare cars in order to find the one matching their needs.</p>
					`,
					links: [
						{ label: "Visit the live version", url: "https://www.mile21.eu" },
/* 						{
							label: "View the prototypes",
							url: [{label: "Homepage", url: "https://design.euroconsumers.org/Mile21/homepage.html?lang=en-gb"},
								{label: "Registration/tracking wizards", url: "https://design.euroconsumers.org/Mile21/modals.html?lang=en-gb"},
								{label: "Account", url: "https://design.euroconsumers.org/Mile21/account/cars.html?lang=en-gb"},
								{label: "Find a car", url: "https://design.euroconsumers.org/Mile21/find-car/list.html?lang=en-gb"},
								{label: "Find a car (compare)", url: "https://design.euroconsumers.org/Mile21/find-car/compare.html?lang=en-gb"},
								{label: "Consumption", url: "https://design.euroconsumers.org/Mile21/consumption/average-no-challenges.html?lang=en-gb"},
								{label: "Compare consumption", url: "https://design.euroconsumers.org/Mile21/consumption/compare-hybrid.html?lang=en-gb"},
								{label: "Consumption history", url: "https://design.euroconsumers.org/Mile21/consumption/history.html?lang=en-gb"},
							],
						}, */
					],
				},
				{
					id: "complaint",
					name: "Complaint platform",
					abstract: `<p>Euroconsumers opened its complaint platform in 2015 to allow consumers to publicly complaint against third party companies, and it has undergone various enhancements and refactories since then. The current version features sophisticated tools for managing the complaints and the communication between the user and the company, as well as a comprehensive onboarding journey to collect data about the incidents.</p>`,
					gallery: [
						{
							desktop: "caas1",
							mobile: "caas1m",
							id: "01",
							caption:
								"<p>The homepage serves as a landing page for the service as well as the first step for the onboarding process (named internally 'complain flow'). One of the first plugin I created for this project is the autosuggest behavior that kickstarts the flow process.</p>",
						},
						{
							desktop: "caas4",
							mobile: "caas4m",
							id: "04",
							caption:
								"<p>The flow process is designed to gather a large amount of data regarding the incident without frustrating the user. It's divided in several steps each of which can have several states depending on the previous answers. Step 4 (pictured) is the most complex one and includes a template management that helps users composing a message and a component to upload documents through another plugin I developed.</p>",
						},
						{
							desktop: "caas2",
							mobile: "caas2m",
							id: "02",
							caption:
								"<p>Part of the success of the complaint platform is due to the pressure exerted on the companies through a transparent and publicly accessible scoring system, that communciate their reliability and reactivity (or lack of thereof) in a clear and user-friendly way.</p>",
						},
						{
							desktop: "caas3",
							mobile: "caas3m",
							id: "03",
							caption:
								"<p>Once a complaint has been opened, the communication between the company and the user who opened it happens on the platform itself. Eventually the customer can choose to close the complaint if the grievance has been resolved, or involve Euroconsumers partner law professionals in the exchange by following an 'escalalation' process.</p>",
						},
					],
					color: "#f9a01e",
					stack: ["Liquid/Html5", "SASS/css3", "jQuery", "Widget factory"],
					roles: [
						"Front-end dev",
						"Photo/illustration editing"
					],
					body: `<p>Euroconsumers complaint platform is a cross-country project started as a POC in 2015 and eventually evolved through several iterations into the complex and exaustive application that is today.</p>
						<p>Like most of the Euroconsumers projects I was involved with, the front-end layer makes use of several jQuery plugins: self-contained and design-agnostic set of complex js behaviors that can be initialized and customized on a page level basis. Most of the plugins I wrote myself for this project, and they eventually found uses across several other of Euroconsumers platforms.</p>
						<p>The front-end is divided in 4 main areas: Homepage, complain flow, thread and company info, all encompassing a vast range of sub-components, variants and special cases.</p>`,
					links: [
						{ label: "Visit the live version", url: "https://www.test-achats.be/plainte" },
/* 						{
							label: "View the prototypes",
							url: [{label: "Homepage", url: "https://design.euroconsumers.org/CAAS/homepage.html?lang=nl-be"},
								{label: "Flow: company info", url: "https://design.euroconsumers.org/CAAS/complain-flow/step1.html?lang=nl-be"},
								{label: "Flow: complaint info", url: "https://design.euroconsumers.org/CAAS/complain-flow/step4.html?lang=nl-be"},
								{label: "Flow: summary", url: "https://design.euroconsumers.org/CAAS/complain-flow/step5.html?lang=nl-be"},
								{label: "Thread", url: "https://design.euroconsumers.org/CAAS/complaint-detail/detail-private-with-answers.html?lang=nl-be"},
								{label: "Thread with escalation", url: "https://design.euroconsumers.org/CAAS/complaint-detail/detail-private-escalated.html?lang=nl-be"},
								{label: "Company page", url: "https://design.euroconsumers.org/CAAS/company-page/default.html?lang=nl-be"},
								{label: "Companies leaderboard", url: "https://design.euroconsumers.org/CAAS/leaderboard.html?lang=nl-be"},
							],
						}, */
					],
				},
				{
					id: "kleisma",
					name: "Kleisma",
					abstract: `<p>Kleisma is a music business strat-up I co-founded with two partners. The main goal of the platform is to connect italian musicians and music professionals, as well as provide an accessible but meaningful stage to present their skills to prospect employers.</p>`,
					gallery: [
						{
							desktop: "kleisma1",
							mobile: "kleisma1m",
							id: "01",
							caption:
								"<p>The profile page is an extremely detailed summary of the musician's career, skills and goals. Given the complexity and diversity of data the main challenge was presenting them in a meaningful and organized way to a potential employer/collaborator and first-time user.</p>",
						},
						{
							desktop: "kleisma2",
							mobile: "kleisma2m",
							id: "02",
							caption:
								"<p>Bands also can create a profile, containing brief information about all components and a summary of the band's career. The challenge was to maintain a design consistency with the musicians profile, while presenting a set of data that is profoundly different.</p>",
						},
						{
							desktop: "kleisma3",
							mobile: "kleisma3m",
							id: "03",
							caption:
								"<p>A search tool allows users to search in detail a specific profile for their need, down to the expected musical education or previous experience. A good balance between complexity and usability was necessary in order to not discourage either a (non-musician) first-time user or a motivated professional with specific goals.</p>",
						},
						{
							desktop: "kleisma4",
							mobile: "kleisma4m",
							id: "04",
							caption:
								"<p>To meet the needs of a fastly shifting business model, we designed a modular system to build new landing/upselling/promo pages crafted on the moment's requirement.</p>",
						},
					],
					color: "#FFCC46",
					stack: ["Razor/Html5", "SASS/css3", "Bootstrap", "jQuery"],
					roles: [
						"Web design",
						"UX design",
						"Front-end dev",
						"Brand identity",
						"Illustrations",
						"Photo editing"
					],
					body: `<p>Kleisma was born out of the necessity for professional and semi-professional musicians to find other musicians or music business professionals of similar level, education, interests and availability.
					While the landscape abounded with well-established competitors, none of the other platforms provided an adequate service, mostly being targeted at amateur and hobbyist musicians.</p>
					<p>I was involved since the inception of the platform by the backend developer and co-founder. To build it we ultimately opted for a stack we were both familiar with (similar to the one I had matured experience upon during my work at Euroconsumers): Asp.net with Razor views, a CMS and jQuery on the front-end. Given the magnitude of the project I opted to add bootstrap as a base to build the custom UI instead of strarting from scratch.</p>
					<p>The platform features a detailed profile for both musicians and bands, as well as an advanced search tool that can be used to sift through results with great depth of details.
					Additionally there are several other tools presenting the aggregated data in a meaningful way, such as a page indexing all live events and a buleltin board with relevant job offers. A number of landing, upselling and promo pages are also present, crafted on occasion following the shifting business model prototypical of young start-ups.</p>`,
					links: [
							{ label: "Visit the live version", url: "https://www.kleisma.com" },
					],
				},
			],
		};
	},
	computed: {
		isXs() {
			return this.windowWidth < this.bpSm;
		},
		isSm() {
			return this.windowWidth >= this.bpSm;
		},
		isMd() {
			return this.windowWidth >= this.bpMd;
		},
		isLg() {
			return this.windowWidth >= this.bpLg;
		},
		prefersTheme() {
			return window.matchMedia("(prefers-color-scheme: dark)").matches
				? "dark"
				: "light";
		},
		scrollbarWidth() {
			return this.getScrollbarWidth();
		},
	},
	created() {
		const self = this;
		self.windowWidth = window.innerWidth;

		window.addEventListener("resize", () => {
			self.windowWidth = window.innerWidth;
			this.trueVh();
			document
				.querySelectorAll("img")
				.forEach((img) => self.imageModuleTrim(img));
		});

		window.addEventListener("scroll", () => {
			this.scrolling = true;
		});
	},
	mounted() {
		const self = this;
		if (!self.theme) {
			self.theme = self.prefersTheme;
		}
		self.updateGrid();
		self.updateTheme();
		self.scrollingDebounce();

		if (this.getScrollbarWidth() > 0) {
			document.documentElement.style.setProperty(
				"--scrollbarWidth",
				this.getScrollbarWidth() + "px"
			);
		}

		window.addEventListener("load", () => {
			self.trueVh();
			document
				.querySelectorAll("img")
				.forEach((img) => self.imageModuleTrim(img));
		});
	},
	methods: {
		trueVh() {
			const totalHeight = document.querySelector("html").offsetHeight;
			document.documentElement.style.setProperty(
				"--totalHeight",
				totalHeight + "px"
			);
		},
		scrollingDebounce() {
			const self = this;
			setInterval(function () {
				if (self.scrolling) {
					self.scrolling = false;
					self.scroll = document.documentElement.scrollTop;
				}
			}, 50);
		},
		updateGrid() {
			this.trueVh();
			const currentGrid = localStorage.getItem("grid")
				? localStorage.getItem("grid")
				: this.grid;
			this.grid = currentGrid;
			const body = document.querySelector("body");
			if (this.grid == "on") {
				body.classList.add("grid");
			} else {
				body.classList.remove("grid");
			}
		},
		updateTheme() {
			const currentTheme = localStorage.getItem("theme")
				? localStorage.getItem("theme")
				: this.theme;
			this.theme = currentTheme;
			document.querySelector("html").dataset.theme =
				this.theme || this.prefersTheme;
		},
		toggleGrid() {
			localStorage.setItem("grid", this.grid == "on" ? "off" : "on");
			this.updateGrid();
		},
		toggleTheme() {
			localStorage.setItem("theme", this.theme == "light" ? "dark" : "light");
			this.updateTheme();
		},
		imageModuleTrim(img, module) {
			module =
				module ||
				parseInt(
					window.getComputedStyle(document.querySelector("html")).fontSize
				);
			if (img.complete) trimImg();
			img.onload = trimImg();

			function trimImg() {
				img.classList.remove("trimmed");
				img.style.height = null;
				img.style.width = null;
				const height = img.clientHeight;
				const width = img.clientWidth;
				const remainder = Math.round(height) % module;

				if (remainder == 0 || height <= module) {
					return false;
				} else {
					img.style.height = height - remainder + "px";
					img.style.width = width + "px";
					img.classList.add("trimmed");
				}
			}
		},
		getScrollbarWidth() {
			// Creating invisible container
			const outer = document.createElement("div");
			outer.style.visibility = "hidden";
			outer.style.overflow = "scroll"; // forcing scrollbar to appear
			outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps
			document.body.appendChild(outer);

			// Creating inner element and placing it in the container
			const inner = document.createElement("div");
			outer.appendChild(inner);

			// Calculating difference between container's full width and the child width
			const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

			// Removing temporary elements from the DOM
			outer.parentNode.removeChild(outer);

			return scrollbarWidth;
		},
	},
};
</script>

<style lang="scss">
@import "@/styles/variables";
@import "@/styles/mixins";
@import "@/styles/layout";

.skip-to-content {
  left: 50%;
  margin: 0;
  position: absolute;
  transform: translate3d(-50%,-200%,0);
  transition: transform 0.2s;
	z-index: 10;

	&:focus {
		transform: translate3d(-50%,0%,0);
	}
}

</style>
