<template>
	<slot name="body" v-if="!active" />

	<transition name="fade" v-if="active">
		<div role="dialog" class="modal" :class="cssClass" v-if="show" ref="modal" aria-modal="true">
			<div class="modal__backdrop" @click="closeModal()" />

			<div class="container">
				<div class="modal__dialog">
					<button
						type="button"
						class="modal__close"
						@click="closeModal()"
						title="close"
					>
						<font-awesome-icon icon="times" />
					</button>
					<div class="modal__body">
						<slot name="body" />
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import keyboard from "@/mixins/keyboard.js";

export default {
	name: "Modal",
	data() {
		return {
			show: false,
			previousFocus: null,
		};
	},
	mixins: [keyboard],
	props: {
		active: {
			type: Boolean,
			default: true,
		},
		cssClass: {
			type: String,
			default: null
			}
	},
	methods: {
		closeModal() {
			this.show = false;
			document.body.classList.remove("showing-modal");
			if (this.previousFocus) this.previousFocus.focus();
		},
		openModal() {
			const self = this;
			this.previousFocus = document.activeElement;
			this.show = true;
			document.body.classList.add("showing-modal");
			setTimeout(function() {
				self.trapFocus(self.$refs.modal);
				self.focusFirstDescendant(self.$refs.modal);
				self.handleEscape(self.$refs.modal, self.closeModal)
			}, 1)
		},
	},
};
</script>

<style lang="scss">
@import "@/styles/variables";
@import "@/styles/mixins";


body.showing-modal {
	overflow: hidden;
	margin-right: var(--scrollbarWidth);
}

.modal {
	overflow-x: hidden;
	overflow-y: auto;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 20;
	text-align: left;
	
	body.showing-modal & {
		margin-right: var(--scrollbarWidth);
	}

	&__backdrop {
		background-color: $black;
		opacity: 0.2;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;

		@include color-scheme(dark) {
			opacity: 0.8;
		}
	}

	&__dialog {
		background-color: $grey0;
		position: relative;
		//width: calc(100% - 2rem);
		margin: 2rem -2rem;
		display: flex;
		flex-direction: column;
		border-radius: 1rem;
		z-index: 2;
		box-shadow: $shadowXLarge;
		transform-origin: center;

		@include color-scheme(dark) {
			@include pseudo-border($primary, $opacity: 0.5);
			box-shadow: $glowXLarge;
		}

		@media (min-width: $bp-md) {
			width: 100rem;
			margin: $container-padding auto;
		}

		@media (min-width: $bp-md) {
			margin: 2rem auto;
			width: 100rem;
			margin: $container-padding auto;
		}
	}

	&__close {
		width: 4rem;
		height: 4rem;
		position: absolute;
		right: 0rem;
		top: 0rem;
		font-size: 4rem;
		border-radius: 50%;
		text-align: center;
		line-height: 0;
		transition: background-color 0.2s ease;

		@media (min-width: $bp-md) {
			top: 1rem;
			right: 1rem;
		}

		svg {
			width: 2rem;
			height: 2rem;

			@media (min-width: $bp-md) {
				top: 3rem;
				right: 3rem;
			}

			path {
				fill: $grey2;
			}
		}

		&:focus,
		&:hover {
			background-color: $grey1;
		}
	}

	&__body {
		padding: 3rem;
		overflow: auto;
		display: flex;
		flex-direction: column;
		align-items: stretch;

		@media (min-width: $bp-md) {
			padding: 8rem 16rem;
		}
	}

	&.fade-enter-active,
	&.fade-leave-active {
		transition: opacity 0.2s ease;

		.modal__dialog {
			transition: transform 0.2s ease;
		}
	}

	&.fade-enter-from,
	&.fade-leave-to {
		opacity: 0;

		.modal__dialog {
			transform: scale3d(80%, 80%, 0);
		}
	}
}
</style>
