<template>
	<div>
		<div
			:style="{
				'--project-color-h': this.hexToHSL(project.color).h,
				'--project-color-s': this.hexToHSL(project.color).s + '%',
				'--project-color-l': this.hexToHSL(project.color).l + '%',
				'--project-text': !project.schema ? '#fff' : '',
			}"
		>
			<ProjectsList :list="[project]" v-bind:single="single" />

			<div class="container" id="top">
				<div class="project-page__body">
					<div class="row">
						<div class="col col-xs-12 col-md-8">
							<article v-html="project.body"></article>
						</div>
						<div class="col col-xs-12 col-md-4">
							<div class="row">
								<div class="col-xs-auto col-sm-auto col-md-12">
									<aside class="project-page__tech"  v-if="project.stack">
										<h2 class="project-page__tech__title">What I used</h2>
										<ul>
											<li v-for="tech in project.stack" :key="tech">
												<font-awesome-icon icon="caret-right" />
												{{ tech }}
											</li>
										</ul>
									</aside>
								</div>
								<div class="col-xs-12 col-sm-auto col-md-12" v-if="project.roles">
									<aside class="project-page__tech">
										<h2 class="project-page__tech__title">My role</h2>
										<ul>
											<li v-for="role in project.roles" :key="role">
												<font-awesome-icon icon="caret-right" />
												{{ role }}
											</li>
										</ul>
									</aside>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Gallery
				:galleryList="project.gallery"
				v-if="project.gallery.length > 1"
			/>

			<Links :links="project.links" v-if="project.links" />
		</div>
	</div>
</template>

<script>
import ProjectsList from "@/components/ProjectsList.vue";
import Links from "@/components/Links.vue";
import colorSpace from "@/mixins/colorSpace.js";
import Gallery from "@/components/Gallery.vue";

export default {
	name: "Project",
	components: {
		Links,
		ProjectsList,
		Gallery,
	},
	data() {
		return {
			single: true,
		};
	},
	computed: {
		project() {
			return this.$root.projects.find((p) => p.id === this.$route.params.id);
		},
	},
	mixins: [colorSpace],
};
</script>

<style lang="scss">
@import "@/styles/variables";
@import "@/styles/mixins";

.project-page__body {
	padding: 8rem 0;

	@media (min-width: $bp-md) {
		padding: 16rem 0;
		@include text-size($font-size-large);
	}

	p {
		margin-bottom: 1rem;
		@include text-size($font-size-base, 'loose')
	}
}

.project-page__tech {
	background-color: $grey1;
	margin: 2rem #{-$container-padding} 0;
	padding: 2rem $container-padding;

	@media (min-width: $bp-md) {
		margin: 0 auto 2rem;
		padding: 2rem 4rem;
	}

	
	.row div:first-child > & {
		@media (max-width: $bp-xs-max) {
		margin-top: 4rem;
		}
		@media (min-width: $bp-md) {
		margin-top: -2rem;
		}
	}

	&__title {
		@include text-size($font-size-small);
		font-weight: 900;
		text-transform: uppercase;
		color: $grey3;
		margin-bottom: 1rem;
	}

	ul {
		li {
			@include text-size($font-size-base);
			&:not(:last-child) {
				margin-bottom: 1rem;
			}

			svg {
				margin-right: 1rem;

				path {
					fill: $primary;
				}
			}
		}
	}
}
</style>
