export default {
	methods: {
		hexToRgb: function(H) {
			// Convert hex to RGB first
			let r = 0, g = 0, b = 0;
			if (H.length == 4) {
				r = "0x" + H[1] + H[1];
				g = "0x" + H[2] + H[2];
				b = "0x" + H[3] + H[3];
			} else if (H.length == 7) {
				r = "0x" + H[1] + H[2];
				g = "0x" + H[3] + H[4];
				b = "0x" + H[5] + H[6];
			}

			return { rgb: 'rgb(' + r + ',' + g + ',' + b + ')', r: r, g: g, b: b }
		},
		hexToHSL: function (H) {
			let r = this.hexToRgb(H).r / 255,
				g = this.hexToRgb(H).g / 255,
				b = this.hexToRgb(H).b / 255

			let cmin = Math.min(r,g,b),
					cmax = Math.max(r,g,b),
					delta = cmax - cmin,
					h = 0,
					s = 0,
					l = 0;
		
			if (delta == 0)
				h = 0;
			else if (cmax == r)
				h = ((g - b) / delta) % 6;
			else if (cmax == g)
				h = (b - r) / delta + 2;
			else
				h = (r - g) / delta + 4;
		
			h = Math.round(h * 60);
		
			if (h < 0)
				h += 360;
		
			l = (cmax + cmin) / 2;
			s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
			s = +(s * 100).toFixed(1);
			l = +(l * 100).toFixed(1);
		
			return { hsl: "hsl(" + h + "," + s + "%," + l + "%)", h: h, s: s, l: l};
		},
		hexContrast: function(H) {
			let r = this.hexToRgb(H).r,
				g = this.hexToRgb(H).g,
				b = this.hexToRgb(H).b
				
				const yiq = ((r*299) + (g*587) + (b*114))/1000;
				
				if (yiq >= 128) {
					return true; //is light
				}
				else {
					return false; //is dark
				}
		}
	}
}