import { createRouter, createWebHistory } from "vue-router";
import Home from "@/pages/Home.vue";
import ProjectPage from "@/pages/ProjectPage.vue";

const routes = [
	{
		path: "/",
		name: "home",
		component: Home,
		meta:{
			title: "Portfolio | Riccardo Farina",
		}
	},
	{
		path: "/project/:id",
		name: "project",
		component: ProjectPage,
		meta:{
			title: "Project page | Riccardo Farina",
		}	},
	{
		path: "/:pathMatch(.*)*",
		name: "NotFound",
		component: Home,
		meta:{
			title: "Page not found | Riccardo Farina",
		}	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	scrollBehavior: function (to, from, savedPosition) {
		if (to.hash) {
			const elem = document.querySelector(to.hash)

			//focus on section for keybord accessibility
			elem.setAttribute('tabindex', '-1');
			elem.focus({preventScroll:true});
			elem.style.outline = 'none';
			elem.addEventListener('blur', () => elem.removeAttribute('tabindex'));

      // vue-router does not incorporate scroll-margin-top on its own.
			const offset = parseInt(getComputedStyle(elem).scrollMarginTop)
			return {
				el: to.hash,
				top: offset,
				behavior: to.path === from.path ? 'smooth' : 'auto'
			}
		} else {
			return {
				top: 0
			};
		}
	},
	routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router;
