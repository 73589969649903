export default {
	data() {
		return {
			keys: {
				end: 35,
				home: 36,
				left: 37,
				up: 38,
				right: 39,
				down: 40,
				delete: 46,
				enter: 13,
				space: 32,
				esc: 27,
				tab: 8,
			},
			openDialogList: [],
			ignoreUtilFocusChanges: false,
			dialogOpenClass: "has-dialog",
		};
	},
	methods: {
		tablist: function (el) {
			/*
			 *   This content is licensed according to the W3C Software License at
			 *   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
			 */
			const tablist = el;
			let tabs;
			//var panels;

			generateArrays();

			function generateArrays() {
				tabs = el.querySelectorAll('[role="tab"]');
				//panels = document.querySelectorAll('[role="tabpanel"]');
			}

			tabs.forEach((tab) => {
				if (tab.getAttribute("aria-selected") !== "true") {
					tab.setAttribute("tabindex", "-1");
				}
			});

			// For easy reference
			const keys = this.keys;

			// Add or subtract depending on key pressed
			const direction = {
				37: -1,
				38: -1,
				39: 1,
				40: 1,
			};

			// Bind listeners
			for (let i = 0; i < tabs.length; ++i) {
				addListeners(i);
			}

			function addListeners(index) {
				tabs[index].addEventListener("click", clickEventListener);
				tabs[index].addEventListener("keydown", keydownEventListener);
				tabs[index].addEventListener("keyup", keyupEventListener);

				// Build an array with all tabs (<button>s) in it
				tabs[index].index = index;
			}

			// When a tab is clicked, activateTab is fired to activate it
			function clickEventListener(event) {
				const tab = event.target;
				activateTab(tab, false);
			}

			// Handle keydown on tabs
			function keydownEventListener(event) {
				const key = event.keyCode;

				switch (key) {
					case keys.end:
						event.preventDefault();
						// Activate last tab
						focusLastTab();
						break;
					case keys.home:
						event.preventDefault();
						// Activate first tab
						focusFirstTab();
						break;

					// Up and down are in keydown
					// because we need to prevent page scroll >:)
					case keys.up:
					case keys.down:
						determineOrientation(event);
						break;
				}
			}

			// Handle keyup on tabs
			function keyupEventListener(event) {
				const key = event.keyCode;

				switch (key) {
					case keys.left:
					case keys.right:
						determineOrientation(event);
						break;
					case keys.delete:
						determineDeletable(event);
						break;
					case keys.enter:
					case keys.space:
						activateTab(event.target);
						break;
				}
			}

			// When a tablist’s aria-orientation is set to vertical,
			// only up and down arrow should function.
			// In all other cases only left and right arrow function.
			function determineOrientation(event) {
				const key = event.keyCode;
				const vertical = tablist.getAttribute("aria-orientation") == "vertical";
				let proceed = false;

				if (vertical) {
					if (key === keys.up || key === keys.down) {
						event.preventDefault();
						proceed = true;
					}
				} else {
					if (key === keys.left || key === keys.right) {
						proceed = true;
					}
				}

				if (proceed) {
					switchTabOnArrowPress(event);
				}
			}

			// Either focus the next, previous, first, or last tab
			// depending on key pressed
			function switchTabOnArrowPress(event) {
				const pressed = event.keyCode;

				if (direction[pressed]) {
					const target = event.target;
					if (target.index !== undefined) {
						if (tabs[target.index + direction[pressed]]) {
							tabs[target.index + direction[pressed]].focus();
						} else if (pressed === keys.left || pressed === keys.up) {
							focusLastTab();
						} else if (pressed === keys.right || pressed == keys.down) {
							focusFirstTab();
						}
					}
				}
			}

			// Activates any given tab panel
			function activateTab(tab, setFocus) {
				setFocus = setFocus || true;
				// Deactivate all other tabs
				deactivateTabs();

				// Remove tabindex attribute
				tab.removeAttribute("tabindex");

				// Set the tab as selected
				tab.setAttribute("aria-selected", "true");

				// Get the value of aria-controls (which is an ID)
				const controls = tab.getAttribute("aria-controls");

				// Remove is-hidden class from tab panel to make it visible
				document.getElementById(controls).classList.remove("is-hidden");

				// Set focus when required
				if (setFocus) {
					tab.focus();
				}
			}

			// Deactivate all tabs and tab panels
			function deactivateTabs() {
				for (let t = 0; t < tabs.length; t++) {
					tabs[t].setAttribute("tabindex", "-1");
					tabs[t].setAttribute("aria-selected", "false");
				}

				/* 				for (var p = 0; p < panels.length; p++) {
					panels[p].classList.add('is-hidden');
				} */
			}

			// Make a guess
			function focusFirstTab() {
				tabs[0].focus();
			}

			// Make a guess
			function focusLastTab() {
				tabs[tabs.length - 1].focus();
			}

			// Detect if a tab is deletable
			function determineDeletable(event) {
				const target = event.target;

				if (target.getAttribute("data-deletable") !== null) {
					// Delete target tab
					deleteTab(event, target);

					// Update arrays related to tabs widget
					generateArrays();

					// Activate the closest tab to the one that was just deleted
					if (target.index - 1 < 0) {
						activateTab(tabs[0]);
					} else {
						activateTab(tabs[target.index - 1]);
					}
				}
			}

			// Deletes a tab and its panel
			function deleteTab(event) {
				const target = event.target;
				const panel = document.getElementById(
					target.getAttribute("aria-controls")
				);

				target.parentElement.removeChild(target);
				panel.parentElement.removeChild(panel);
			}
		},
		toggleTab: function (wrapper, tabbable) {
			let elements = [];
			//if (!NodeList.prototype.isPrototypeOf(wrapper)) wrapper = [wrapper];

			wrapper.forEach((i) => {
				elements.push(...this.allFocusableDescendants(i));
			});

			if (tabbable) {
				wrapper.forEach((i) => {
					i.setAttribute("aria-hidden", false);
				});
				elements.forEach((i) => i.setAttribute("tabindex", 0));
			} else {
				wrapper.forEach((i) => {
					i.setAttribute("aria-hidden", true);
				});
				elements.forEach((i) => i.setAttribute("tabindex", -1));
			}
		},
		firstFocusableDescendant: function(element) {
			const allFocusable = this.allFocusableDescendants(element);
			return allFocusable.length ? allFocusable[0] : false;  
		},
		lastFocusableDescendant: function(element) {
			const allFocusable = this.allFocusableDescendants(element);
			return allFocusable.length ? allFocusable[allFocusable.length - 1] : false;
		},
		allFocusableDescendants: function(element, arr) {
			arr = arr || [];
			for (var i = 0; i < element.childNodes.length; i++) {
				var child = element.childNodes[i];
				if (this.isFocusable(child)) {
					arr.push(child);
				}
				else {
					this.allFocusableDescendants(child, arr)
				}
			}

			return arr;
		},
		focusFirstDescendant: function (element) {
			/**
			 * @description Set focus on descendant nodes until the first focusable element is
			 *       found.
			 * @param element
			 *          DOM node for which to find the first focusable descendant.
			 * @returns {boolean}
			 *  true if a focusable element is found and focus is set.
			 */
			for (var i = 0; i < element.childNodes.length; i++) {
				var child = element.childNodes[i];
				if (this.attemptFocus(child) || this.focusFirstDescendant(child)) {
					return true;
				}
			}
			return false;
		},
		focusLastDescendant: function (element) {
			/**
			 * @description Find the last descendant node that is focusable.
			 * @param element
			 *          DOM node for which to find the last focusable descendant.
			 * @returns {boolean}
			 *  true if a focusable element is found and focus is set.
			 */
			for (var i = element.childNodes.length - 1; i >= 0; i--) {
				var child = element.childNodes[i];
				if (this.attemptFocus(child) || this.focusLastDescendant(child)) {
					return true;
				}
			}
			return false;
		},
		attemptFocus: function (element) {
			/**
			 * @description Set Attempt to set focus on the current node.
			 * @param element
			 *          The node to attempt to focus on.
			 * @returns {boolean}
			 *  true if element is focused.
			 */
			if (!this.isFocusable(element)) {
				return false;
			}

			this.ignoreUtilFocusChanges = true;
			try {
				element.focus();
			} catch (e) {
				// continue regardless of error
			}
			this.ignoreUtilFocusChanges = false;
			return document.activeElement === element;
		},
		handleEscape: function (element, next) {
			element.addEventListener('keyup', (event) => {
				var key = event.which || event.keyCode;

				if (key === this.keys.esc) {
					event.stopPropagation();
					next();
				}
			})
			
		},
		isFocusable: function (element) {
			if (element.tabIndex < 0) {
				return false;
			}
		
			if (element.disabled) {
				return false;
			}
		
			switch (element.nodeName) {
				case 'A':
					return !!element.href && element.rel != 'ignore';
				case 'INPUT':
					return element.type != 'hidden';
				case 'BUTTON':
				case 'SELECT':
				case 'TEXTAREA':
					return true;
				default:
					return false;
			}
		},
		trapFocus: function(element) {
			var focusableEls = this.allFocusableDescendants(element);
			var firstFocusableEl = focusableEls[0];  
			var lastFocusableEl = focusableEls[focusableEls.length - 1];
			var self = this;
		
			element.addEventListener('keydown', function(e) {
				var isTabPressed = (e.key === 'Tab' || e.keyCode === self.keys.tab);
		
				if (!isTabPressed) { 
					return; 
				}
		
				if ( e.shiftKey ) /* shift + tab */ {
					if (document.activeElement === firstFocusableEl) {
						lastFocusableEl.focus();
							e.preventDefault();
						}
					} else /* tab */ {
					if (document.activeElement === lastFocusableEl) {
						firstFocusableEl.focus();
							e.preventDefault();
						}
					}
			});
		},
	},
};
