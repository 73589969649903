export default {
	methods: {
		waypoint: function (el, threshold, enter, exit) {
			var observer = new IntersectionObserver(
				function (entries) {
					if (entries[0].isIntersecting === true) enter();
					else if (exit) exit();
				},
				{ threshold: threshold }
			);
			observer.observe(el);
		},
	},
};
